import React, { CSSProperties } from 'react'
import { CursorProps } from 'react-arborist'

export const cursorBlue = '#4B91E2'

const placeholderStyle = {
  display: 'flex',
  alignItems: 'center',
  zIndex: 1000,
}

const lineStyle = {
  flex: 1,
  height: '2px',
  background: cursorBlue,
  borderRadius: '1px',
}

const circleStyle = {
  width: '4px',
  height: '4px',
  boxShadow: `0 0 0 3px ${cursorBlue}`,
  borderRadius: '50%',
}

export const TreeCursor = React.memo(function DefaultCursor({
  top,
  left,
  indent,
}: CursorProps) {
  const depth = left / indent
  let leftMargin = (depth + 1) * 8
  if (depth > 0) leftMargin += 8

  const topOffset = top === 0 ? 0 : 5

  const style: CSSProperties = {
    position: 'absolute',
    pointerEvents: 'none',
    top: top - topOffset + 'px',
    left: leftMargin + 'px',
    right: indent + 'px',
  }

  return (
    <div style={{ ...placeholderStyle, ...style }}>
      <div style={{ ...circleStyle }}></div>
      <div style={{ ...lineStyle }}></div>
    </div>
  )
})
