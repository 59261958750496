import {
  IconBolt,
  IconBriefcase,
  IconBuildingSkyscraper,
  IconColumns2,
  IconTargetArrow,
  IconUser,
} from '@tabler/icons-react'

import { logger } from './logger'
import { NativeSuggestedPipelineTypes } from './relationshipSummary'

export const GlobalWorkspace = 'global'

export const NativeObjectTypes = {
  Person: 'native_contact',
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
  Workspace: 'native_workspace',
} as const

export const PropertyTypes = {
  Address: 'address',
  Calculated: 'calculated',
  Combobox: 'combobox',
  Currency: 'currency',
  Email: 'email',
  MultiPicklist: 'multipicklist',
  Percent: 'percent',
  Picklist: 'picklist',
  Phone: 'phone',
  ObjectReference: 'reference',
  TextArea: 'textarea',
  Url: 'url',
  DateTime: 'datetime',
} as const

export const ObjectAccessLevels = {
  Owner: 'owner',
  FullAccess: 'fullAccess',
  Editor: 'editor',
  Collaborator: 'collaborator',
  Viewer: 'viewer',
} as const

export const ObjectTypeMetadata = {
  [NativeObjectTypes.Organization]: {
    icon: IconBuildingSkyscraper,
    label: 'Organization',
    pluralLabel: 'Organizations',
    slug: 'organizations',
  },
  [NativeObjectTypes.Person]: {
    icon: IconUser,
    label: 'Person',
    pluralLabel: 'People',
    slug: 'people',
  },
  [NativeObjectTypes.Opportunity]: {
    icon: IconTargetArrow,
    label: 'Opportunity',
    pluralLabel: 'Opportunities',
    slug: 'opportunities',
    paths: {
      CREATION: {
        key: 'opportunities/create',
        label: 'Opportunity Creation',
      },
    },
  },
  [NativeObjectTypes.Pipeline]: {
    icon: IconColumns2,
    label: 'Pipeline',
    pluralLabel: 'Pipelines',
    slug: 'pipelines',
  },
  [NativeObjectTypes.Workspace]: {
    icon: IconBriefcase,
    label: 'Workspace',
    pluralLabel: 'Workspaces',
    slug: 'workspace',
  },
  [NativeObjectTypes.Action]: {
    icon: IconBolt,
    label: 'Action',
    pluralLabel: 'Actions',
    slug: 'actions',
    instructionsLabel: 'Instructions for Actions',
  },
}

export type RelationshipDirection = 'TO' | 'FROM' | 'MUTUAL' | 'REFERRER'

export const RelationshipDirectionalities: Record<
  string,
  RelationshipDirection
> = {
  To: 'TO',
  From: 'FROM',
  Referrer: 'REFERRER',
} as const

export type RelationshipStage = 'PROSPECTIVE' | 'EXISTING' | 'ABANDONED'

export const RelationshipStages: Record<string, RelationshipStage> = {
  Prospective: 'PROSPECTIVE',
  Existing: 'EXISTING',
  Abandoned: 'ABANDONED',
} as const

export type RelationshipType =
  | 'CUSTOMER'
  | 'INVESTMENT'
  | 'PARTNER'
  | 'MENTOR'
  | 'INTERNAL'
  | 'PERSONAL'
  | 'OTHER'

export const RelationshipTypes: Record<string, RelationshipType> = {
  Customer: 'CUSTOMER',
  Investment: 'INVESTMENT',
  Partner: 'PARTNER',
  Mentor: 'MENTOR',
  Internal: 'INTERNAL',
  Personal: 'PERSONAL',
  Other: 'OTHER',
} as const

/*
 workspaceId: xyz-123


 objectId: pixelmachinery.com
 objectType: org name: relTypes
 value: ["CUSTOMER : TO : PROSPECTIVE", "CUSTOMER : FROM : EXISTING"]

 objectId: sarah@pillar.vc
 objectType: person name: relTypes
 value: ["MENTOR : MUTUAL : EXISTING", "CUSTOMER : TO : PROSPECTIVE", "INVESTMENT : FROM : EXISTING",
 "INVESTMENT : FROM : PROSPECTIVE", "INVESTMENT : REFERRER : PROSPECTIVE",
 "CUSTOMER : REFERRER : EXISTING"

 objectId: niriksha@hprime.co
 objectType: person name: relTypes
 value: ["CUSTOMER : REFERRER : EXISTING"]

 objectId: brightmindsneuropsych.com
 objectType: org
 userId: christopherUserId
 value: ["CUSTOMER : TO : EXISTING"]

 */

export type RelationshipTypeMetadata = {
  label: string
  key: string
  pipelineType: string | null
}

export const NativeRelationshipTypes: {
  [key in RelationshipType]: {
    [key in RelationshipDirection]: {
      [key in RelationshipStage]: RelationshipTypeMetadata
    }
  }
} = {
  [RelationshipTypes.Customer]: {
    [RelationshipDirectionalities.To]: {
      [RelationshipStages.Prospective]: {
        label: 'Sales Prospect',
        key: 'PROSPECTIVE_CUSTOMER',
        pipelineType: NativeSuggestedPipelineTypes.NEW_CUSTOMER.key,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Customer',
        key: 'EXISTING_CUSTOMER',
        pipelineType: NativeSuggestedPipelineTypes.EXISTING_CUSTOMER.key,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Closed Lost Sales Prospect',
        key: 'CLOSED_LOST_CUSTOMER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Vendor',
        key: 'PROSPECTIVE_VENDOR',
        pipelineType: NativeSuggestedPipelineTypes.VENDOR_PROCUREMENT.key,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Vendor',
        key: 'EXISTING_VENDOR',
        pipelineType: NativeSuggestedPipelineTypes.VENDOR_PROCUREMENT.key,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Former Vendor',
        key: 'CLOSED_LOST_VENDOR',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Investment]: {
    [RelationshipDirectionalities.From]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Investor',
        key: 'PROSPECTIVE_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Investor',
        key: 'EXISTING_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Passed Investor',
        key: 'CLOSED_LOST_INVESTMENT',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.To]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Investment',
        key: 'PROSPECTIVE_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Investment',
        key: 'EXISTING_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Declined Investment',
        key: 'CLOSED_LOST_INVESTMENT',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Partner]: {
    [RelationshipDirectionalities.To]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Partner',
        key: 'PROSPECTIVE_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Partner',
        key: 'EXISTING_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
    },
    [RelationshipDirectionalities.From]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Partner',
        key: 'PROSPECTIVE_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Partner',
        key: 'EXISTING_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Former Partner',
        key: 'CLOSED_LOST_PARTNER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Mentor]: {
    [RelationshipDirectionalities.To]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Mentee',
        key: 'PROSPECTIVE_MENTEE',
        pipelineType: null,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Mentee',
        key: 'EXISTING_MENTEE',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Former Mentee',
        key: 'CLOSED_LOST_MENTEE',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From]: {
      [RelationshipStages.Prospective]: {
        label: 'Prospective Mentor',
        key: 'PROSPECTIVE_MENTOR',
        pipelineType: null,
      },
      [RelationshipStages.Existing]: {
        label: 'Current Mentor',
        key: 'EXISTING_MENTOR',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned]: {
        label: 'Former Mentor',
        key: 'CLOSED_LOST_MENTOR',
        pipelineType: null,
      },
    },
  },
}

export const buildRelationshipReference = (
  type: RelationshipType,
  directionality: RelationshipDirection,
  stage: RelationshipStage
) => {
  return `${type} : ${directionality} : ${stage}`
}

export const parseRelationshipReference = (reference: string) => {
  if (!reference || typeof reference !== 'string') {
    logger.warn('Invalid relationship reference - must be a non-empty string')
    return null
  }

  const parts = reference.split(' : ')
  if (parts.length !== 3) {
    logger.warn(
      'Invalid relationship reference format - must contain exactly 3 parts separated by " : "'
    )
    return null
  }

  const [type, directionality, stage] = parts

  if (!type || !directionality || !stage) {
    logger.error('Invalid relationship reference - all parts must be non-empty')
    return null
  }

  return { type, directionality, stage }
}

export const mergeObjects = (obj1: any, obj2: any): any => {
  if (!obj1 || typeof obj1 !== 'object') {
    logger.dev({ msg: 'obj1 is not an object', obj1, obj2 })
    return obj2
  }
  if (!obj2 || typeof obj2 !== 'object') {
    logger.dev({ msg: 'obj2 is not an object', obj1, obj2 })
    return obj1
  }

  const result = { ...obj1 }

  for (const key in obj2) {
    if (obj2[key] === null) {
      continue // Skip null values in obj2
    } else if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
      result[key] = mergeObjects(obj1[key] || {}, obj2[key])
    } else {
      result[key] = obj2[key]
    }
  }

  return result
}
