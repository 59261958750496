import React, {
  useState,
  createContext,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from 'react'

import type {
  GlobalWorkAccountWorkspaceConnectionsQuery,
  Organization,
  Person,
  Pipeline,
} from 'types/graphql'
import { WorkAccountWorkspaceConnection, Workspace } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router/dist/useRouteName'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { onboardingStatus } from 'src/lib/welcome'

import { extractEmailDomain } from './contactFormatting'
import { ungatedForMeetingRecordingCache } from './gates'
import { logger } from './logger'
import { mergeObjects } from './objects'

const USER_CORE_CONTACT_QUERY = gql`
  query UserCoreContactQuery($email: String!) {
    coreContactFromPerson(email: $email) {
      id
      email
      username
      firstName
      lastName
      title
      domain
      industry
      photo
      department
      description
      unsubscribed
      linkedInUrl
      linkInBioJson
      timezone
      travelTimezone
    }
  }
`

const WORK_ACCOUNTS_QUERY = gql`
  query DayContextFindWorkAccountByOwnerEmail($ownerEmail: String!) {
    workAccounts(ownerEmail: $ownerEmail) {
      id
      createdAt
      uuid
      email
      name
      tokenError
      scopes
      crm3WorkspaceId
      googleSyncStatuses {
        type
        status
        reason
        statusChangedAt
        lastSeenSyncToken
        lastSyncedAt
      }
      calendarAutoRecordSettings {
        mode
        availableModes
      }
      notificationSubscriptionOverrides {
        notificationKey
        enabled
      }
      workspaces {
        id
        name
        domains {
          domain
        }
      }
    }
  }
`

const FETCH_WORKSPACES_AND_ROLES = gql`
  query FetchWorkspacesAndRoles($userEmail: String!) {
    workspaces(userEmail: $userEmail) {
      id
      name
      status
      domains {
        domain
        autoInvite
        autoInviteRoleId
      }
      claimableDomains
      isDefault
      members {
        id
        email
        status
        isDefaultOwner
        roleId
        coreContact {
          firstName
          lastName
          photo
          title
        }
      }
      roles {
        id
        name
        description
        permissions {
          type
          permission
          scope
        }
      }
    }
  }
`

const WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY = gql`
  query GlobalWorkAccountWorkspaceConnectionsQuery {
    workAccountWorkspaceConnections {
      workAccountUuid
      connectedWorkspaces {
        workspaceId
        sharingRules {
          category
          selector {
            type
            expression
          }
          authorization {
            type
            id
          }
        }
      }
    }
  }
`

const MEETING_RECORDINGS_FOR_CONTEXT = gql`
  query MeetingRecordingsForContext(
    $workspaceId: String!
    $limit: Int!
    $page: Int!
  ) {
    workspaceMeetingRecordings(
      workspaceId: $workspaceId
      limit: $limit
      page: $page
    ) {
      pageInfo {
        limit
        page
        totalPages
      }
      recordings {
        id
        title
        summary {
          output
        }
        startedAt
        endedAt
        statusHistory {
          level
          status
          createdAt
          reason
          message
        }
        participants {
          email
        }
        clips {
          id
          startSeconds
          endSeconds
        }
        calendarEvents {
          id
          GoogleEvent {
            title
          }
        }
      }
    }
  }
`

const GET_PIPELINES_FOR_DAYCONTEXT = gql`
  query GetPipelinesForDayContext($workspaceId: String!) {
    pipelines(workspaceId: $workspaceId) {
      id
      workspaceId
      title
      type
      isGeneric
      stages {
        id
        title
        type
        opportunities {
          id
          title
          domain
          roles {
            personEmail
            roles
          }
        }
        position
      }
    }
  }
`

const GET_SUGGESTED_EXCLUSIONS_CONTEXT = gql`
  query GetSuggestedExclusions($workspaceId: String!, $workAccountId: String!) {
    getSuggestedExclusions(
      workspaceId: $workspaceId
      workAccountId: $workAccountId
    ) {
      reason
      domain
      category
    }
  }
`

const GET_WORKSPACE_PEOPLE_CONTEXT = gql`
  query GetWorkspacePeople($workspaceId: String!, $page: Int) {
    workspacePeople(workspaceId: $workspaceId, page: $page) {
      id
      fullName
      currentJobTitle
      photoUrl
      email
      linkedInUrl
      relationship {
        type
        oneSentence
      }
    }
  }
`

const GET_WORKSPACE_ORGANIZATIONS_1 = gql`
  query GetWorkspaceOrganizations($workspaceId: String!) {
    workspaceOrganizations(workspaceId: $workspaceId) {
      id
      name
      domain
      photos {
        id
        square
      }
      lifecycle {
        id
        pipelineType
        stageType
        stageNumber
      }
      roles {
        id
        email
        role
      }
    }
  }
`

interface DayContextProps {
  userCoreContact: any
  refetchUserCoreContact: () => void
  workAccounts: any[]
  setWorkAccounts: (workAccounts: any[]) => void
  refetchWorkAccounts: () => void
  workspaces: Workspace[]
  refetchWorkspaces: () => Promise<any>
  sidebarObject: any
  setSidebarObject: (sidebarObject: any) => void
  workAccountWorkspaceConnections: WorkAccountWorkspaceConnection[]
  refetchWorkAccountWorkspaceConnections: () => void
  selectedWorkspace: string | null
  setSelectedWorkspace: (workspaceId: string) => void
  people: any[]
  refetchPeople: () => void
  loading: boolean
  internalDomains: string[]
  selectedWorkspaceLoading: boolean
  suggestedExclusions: any[]
  refetchSuggestedExclusions: () => void
  workspacePeople: Person[]
  workspaceOrganizations: Organization[]
  refetchWorkspaceOrganizations: () => void
  orgsByDomain: Record<string, Organization>
  peopleByEmail: Record<string, Person>
  pipelines: Pipeline[]
  refetchPipelines: () => void
}

export const DayContext = createContext<DayContextProps>({
  userCoreContact: null,
  refetchUserCoreContact: () => {},
  workAccounts: [],
  setWorkAccounts: () => {},
  refetchWorkAccounts: () => {},
  workspaces: [],
  refetchWorkspaces: async () => {},
  workAccountWorkspaceConnections: null,
  refetchWorkAccountWorkspaceConnections: () => {},
  sidebarObject: null,
  setSidebarObject: () => {},
  selectedWorkspace: null,
  setSelectedWorkspace: () => {},
  people: [],
  refetchPeople: () => {},
  loading: false,
  internalDomains: [],
  selectedWorkspaceLoading: true,
  suggestedExclusions: [],
  refetchSuggestedExclusions: () => {},
  workspacePeople: [],
  workspaceOrganizations: [],
  refetchWorkspaceOrganizations: () => {},
  orgsByDomain: {},
  peopleByEmail: {},
  pipelines: [],
  refetchPipelines: () => {},
})

interface DayProviderProps {
  children: ReactNode
}

// LocalStorage will happily stringify null/undefined values
// and then hand them back to you as strings, which messes up
// truthy/falsey checks. Let's immediately convert them back
// before using them in business logic.
const parseLocalStorageValue = (value: string) => {
  if (value === 'null' || value === 'undefined') return null

  return value
}

export const DayProvider: React.FC<DayProviderProps> = ({ children }) => {
  const { currentUser } = useAuth()
  const route = useRouteName()
  const [sidebarObject, setSidebarObject] = useState<any>(null)
  const [workAccounts, setWorkAccounts] = useState<any[]>([])
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [workAccountWorkspaceConnections, setWorkAccountWorkspaceConnections] =
    useState<WorkAccountWorkspaceConnection[]>([])
  const [people, setPeople] = useState<any[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(
    null
  )
  const [selectedWorkspaceLoading, setSelectedWorkspaceLoading] = useState(true)
  const [meetingRecordings, setMeetingRecordings] = useState<any[]>([])
  const [meetingRecordingsPageInfo, setMeetingRecordingsPageInfo] =
    useState<any>(null)
  const [meetingRecordingsPage, setMeetingRecordingsPage] = useState<number>(1)

  const {
    data: userCoreContactData,
    refetch: refetchUserCoreContactQuery,
    loading: userCoreContactLoading,
  } = useQuery(USER_CORE_CONTACT_QUERY, {
    variables: { email: currentUser?.email },
    skip: !currentUser,
  })

  const refetchUserCoreContact = useCallback(() => {
    refetchUserCoreContactQuery()
  }, [refetchUserCoreContactQuery])

  const userCoreContact = userCoreContactData?.coreContactFromPerson

  const PRECACHE_MEETING_RECORDINGS = ungatedForMeetingRecordingCache.includes(
    currentUser?.email
  )

  const { refetch: refetchMeetingRecordings } = useQuery(
    MEETING_RECORDINGS_FOR_CONTEXT,
    {
      variables: {
        limit: 20,
        page: meetingRecordingsPage,
        workspaceId: selectedWorkspace,
      },
      onCompleted: (data) => {
        setMeetingRecordings((prev) => [
          ...prev,
          ...data.workspaceMeetingRecordings.recordings,
        ])
        setMeetingRecordingsPageInfo(data.workspaceMeetingRecordings.pageInfo)
        setMeetingRecordingsPage(
          data.workspaceMeetingRecordings.pageInfo.page + 1
        )
      },
      skip:
        !selectedWorkspace ||
        !PRECACHE_MEETING_RECORDINGS ||
        meetingRecordingsPageInfo?.totalPages < meetingRecordingsPage,
    }
  )

  const {
    data: workAccountsData,
    refetch: refetchWorkAccountsQuery,
    loading: workAccountsLoading,
  } = useQuery(WORK_ACCOUNTS_QUERY, {
    variables: {
      ownerEmail: currentUser?.email,
    },
    skip: !currentUser,
    onCompleted: (data) => {
      setWorkAccounts(data.workAccounts)
    },
  })

  const refetchWorkAccounts = useCallback(() => {
    refetchWorkAccountsQuery()
  }, [refetchWorkAccountsQuery])

  const {
    data: workspacesData,
    refetch: refetchWorkspacesQuery,
    loading: workspacesLoading,
  } = useQuery(FETCH_WORKSPACES_AND_ROLES, {
    variables: { userEmail: currentUser?.email },
    skip: !currentUser,
    onCompleted: (data) => {
      const workspacesFromQuery = data.workspaces || []

      setWorkspaces(workspacesFromQuery)
    },
  })

  const refetchWorkspaces = useCallback(() => {
    return refetchWorkspacesQuery()
  }, [refetchWorkspacesQuery])

  const [workspacePeoplePage, setWorkspacePeoplePage] = useState(0)
  const [isLoadingMorePeople, setIsLoadingMorePeople] = useState(false)
  const [hasMorePeople, setHasMorePeople] = useState(true)

  const { data: workspacePeopleData, refetch: refetchWorkspacePeopleQuery } =
    useQuery(GET_WORKSPACE_PEOPLE_CONTEXT, {
      variables: { workspaceId: selectedWorkspace, page: workspacePeoplePage },
      skip: !selectedWorkspace || !hasMorePeople,
      onCompleted: (data) => {
        if (data?.workspacePeople?.length === 0) {
          logger.dev('No more people to load')
          setHasMorePeople(false)
          return
        }
        logger.dev(`Loaded ${data.workspacePeople.length} more people`)

        setPeopleByEmail((prev) => ({
          ...prev,
          ...data.workspacePeople.reduce(
            (acc, person) => {
              // Merge new person data with existing data if it exists
              acc[person.email] = {
                ...prev[person.email], // Keep existing data as base
                ...person, // Overlay with new data
                // Deep merge specific nested objects if needed
                relationship: {
                  ...(prev[person.email]?.relationship || {}),
                  ...(person.relationship || {}),
                },
              }
              return acc
            },
            {} as Record<string, Person>
          ),
        }))

        setWorkspacePeoplePage((page) => page + 1)
        setIsLoadingMorePeople(false)
      },
    })

  const [peopleByEmail, setPeopleByEmail] = useState<Record<string, Person>>({})

  // Derive peopleByDomain from peopleByEmail
  const peopleByDomain = useMemo(() => {
    return Object.values(peopleByEmail).reduce(
      (acc, person) => {
        const domain = extractEmailDomain(person.email)
        acc[domain] = acc[domain] || []
        acc[domain].push(person)
        return acc
      },
      {} as Record<string, Person[]>
    )
  }, [peopleByEmail])

  const workspacePeople = useMemo(() => {
    return Object.values(peopleByEmail)
  }, [peopleByEmail])

  // Start loading when workspace changes
  useEffect(() => {
    if (selectedWorkspace) {
      setWorkspacePeoplePage(0)
      setPeopleByEmail({})
      setHasMorePeople(true)
      setIsLoadingMorePeople(true)
    }
  }, [selectedWorkspace])

  // Continue loading next page
  useEffect(() => {
    if (hasMorePeople && !isLoadingMorePeople && selectedWorkspace) {
      setIsLoadingMorePeople(true)
    }
  }, [hasMorePeople, isLoadingMorePeople, selectedWorkspace])

  const refetchPeople = useCallback(() => {
    refetchWorkspacePeopleQuery()
  }, [refetchWorkspacePeopleQuery])

  const {
    data: workspaceOrganizationsData,
    refetch: refetchWorkspaceOrganizationsBasic,
  } = useQuery(GET_WORKSPACE_ORGANIZATIONS_1, {
    variables: { workspaceId: selectedWorkspace },
    skip: !selectedWorkspace,
  })

  const { data: pipelinesData, refetch: refetchPipelinesQuery } = useQuery(
    GET_PIPELINES_FOR_DAYCONTEXT,
    {
      variables: { workspaceId: selectedWorkspace },
      skip: !selectedWorkspace,
      onCompleted: (dayContextPipelinesData) => {
        logger.dev(dayContextPipelinesData)
      },
    }
  )

  const pipelines = useMemo(() => {
    return pipelinesData?.pipelines || []
  }, [pipelinesData])

  const refetchPipelines = useCallback(() => {
    refetchPipelinesQuery()
  }, [refetchPipelinesQuery])

  const refetchWorkspaceOrganizations = useCallback(() => {
    refetchWorkspaceOrganizationsBasic()
  }, [refetchWorkspaceOrganizationsBasic])

  const [
    selectedWorkAccountForSuggestedExclusions,
    setSelectedWorkAccountForSuggestedExclusions,
  ] = useState<{ uuid: string } | undefined>(undefined)
  const { data: suggestedExclusionsData, refetch: refetchSuggestedExclusions } =
    useQuery(GET_SUGGESTED_EXCLUSIONS_CONTEXT, {
      variables: {
        workspaceId: selectedWorkspace,
        workAccountId: selectedWorkAccountForSuggestedExclusions?.uuid,
      },
      skip: !selectedWorkspace || !selectedWorkAccountForSuggestedExclusions,
    })

  const suggestedExclusions = useMemo(() => {
    try {
      if (
        workAccountWorkspaceConnections?.length > 0 &&
        workAccounts?.length > 0 &&
        !selectedWorkAccountForSuggestedExclusions
      ) {
        for (const connection of workAccountWorkspaceConnections) {
          if (
            (connection?.connectedWorkspaces ?? []).some(
              (workspace) => workspace?.workspaceId === selectedWorkspace
            )
          ) {
            setSelectedWorkAccountForSuggestedExclusions(
              workAccounts.find(
                (workAccount) => workAccount.uuid === connection.workAccountUuid
              )
            )
            break
          }
        }
      }
    } catch (error) {
      logger.warn('Error fetching suggested exclusions')
    }

    return suggestedExclusionsData?.getSuggestedExclusions || []
  }, [
    suggestedExclusionsData,
    workAccountWorkspaceConnections,
    workAccounts,
    selectedWorkspace,
    selectedWorkAccountForSuggestedExclusions,
  ])

  const orgsByDomain = useMemo(() => {
    if (!workspaceOrganizationsData?.workspaceOrganizations) return {}
    const orgs = []
    const dataSet = [
      ...(workspaceOrganizationsData?.workspaceOrganizations || []),
    ]

    for (const org of dataSet) {
      if (org.domain) {
        orgs[org.domain] = mergeObjects(orgs[org.domain] || {}, org)
      }
    }

    for (const domain of Object.keys(orgs)) {
      orgs[domain].people = peopleByDomain?.[domain] || []
    }

    return orgs
  }, [workspaceOrganizationsData?.workspaceOrganizations, peopleByDomain])

  const workspaceOrganizations = useMemo(() => {
    const orgs = Object.values(orgsByDomain) as Organization[]
    return orgs
  }, [orgsByDomain])

  const selectedWorkspaceFromLocalStorage = parseLocalStorageValue(
    localStorage.getItem('selectedWorkspaceV2')
  )

  const defaultWorkspaceId = workspaces[0]?.id

  // The defaultWorkspaceId check ensures we don't try to operate on loading/missing data (i.e. empty array)
  // The !selectedWorkspaceFromLocalStorage check catches the case where no workspace is stored yet
  // The includes() check catches the case where the user previous had access to a workspace and it
  // was stored as their selection, but they no longer have access to it so it's stored in storage but
  // not in the list of available workspaces to choose from.
  if (
    defaultWorkspaceId &&
    (!selectedWorkspaceFromLocalStorage ||
      !workspaces
        ?.map(({ id }) => id)
        ?.includes(selectedWorkspaceFromLocalStorage))
  ) {
    localStorage.setItem('selectedWorkspaceV2', defaultWorkspaceId)
  }

  if (
    !selectedWorkspace &&
    defaultWorkspaceId &&
    workspaces &&
    workspaces.length > 0
  ) {
    setSelectedWorkspace(
      selectedWorkspaceFromLocalStorage &&
        workspaces
          .map(({ id }) => id)
          .includes(selectedWorkspaceFromLocalStorage)
        ? selectedWorkspaceFromLocalStorage
        : defaultWorkspaceId
    )
    setSelectedWorkspaceLoading(false)
  }

  /*
  TODO: discuss if we want this fallback or not

  if (!selectedWorkspace && selectedWorkspaceFromLocalStorage)
    setSelectedWorkspace(selectedWorkspaceFromLocalStorage)
  */

  useEffect(() => {
    if (selectedWorkspace) {
      localStorage.setItem('selectedWorkspaceV2', selectedWorkspace)
    }
  }, [selectedWorkspace])

  // TODO: discuss if we want to CLEAR the selected workspace from localStorage if the user no longer has access to it

  const {
    data: workAccountWorkspaceConnectionsData,
    refetch: refetchWorkAccountWorkspaceConnections,
    loading: workAccountWorkspaceConnectionsLoading,
  } = useQuery<GlobalWorkAccountWorkspaceConnectionsQuery>(
    WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY,
    {
      onCompleted: (data) => {
        setWorkAccountWorkspaceConnections(data.workAccountWorkspaceConnections)
      },
    }
  )

  const internalDomains = useMemo(() => {
    if (
      !workspaces ||
      !workAccounts ||
      workspaces.length === 0 ||
      workAccounts.length === 0 ||
      !Array.isArray(workspaces) ||
      !Array.isArray(workAccounts)
    )
      return []
    const internal = new Set(
      workspaces
        ?.map((workspace) => {
          if (!workspace?.domains || !Array.isArray(workspace.domains))
            return []
          return workspace.domains.map((domain) => domain.domain)
        })
        ?.flat()
    )
    for (const domain of workAccounts.map((account) =>
      extractEmailDomain(account.email)
    )) {
      internal.add(domain)
    }
    const uniqueInternalDomains = Array.from(internal).filter(Boolean)
    return uniqueInternalDomains
  }, [workspaces, workAccounts])

  useEffect(() => {
    if (
      userCoreContact &&
      workspacesData &&
      workAccountsData &&
      selectedWorkspace &&
      workAccountWorkspaceConnectionsData &&
      !(
        workAccountWorkspaceConnectionsLoading ||
        userCoreContactLoading ||
        workAccountsLoading ||
        workspacesLoading
      )
    ) {
      const targetWorkspace = workspacesData.workspaces.find(
        (workspace) => workspace.id === selectedWorkspace
      )

      const { isComplete } = onboardingStatus({
        coreContact: userCoreContact,
        workAccounts: workAccountsData.workAccounts,
        workspace: targetWorkspace,
        workAccountWorkspaceConnections:
          workAccountWorkspaceConnectionsData.workAccountWorkspaceConnections,
      })

      const needsOnboarding = !isComplete

      const excludedRoutesFromForcedOnboarding = ['revokeAlert'] // Add more if necessary

      if (
        needsOnboarding &&
        !excludedRoutesFromForcedOnboarding.includes(route)
      )
        navigate(routes.welcome())
    }
  }, [
    userCoreContact,
    workAccountsData,
    workspacesData,
    selectedWorkspace,
    workAccountWorkspaceConnectionsData,
    route,
    workAccountWorkspaceConnectionsLoading,
    userCoreContactLoading,
    workAccountsLoading,
    workspacesLoading,
  ])

  const loading = useMemo(() => {
    return (
      workAccountWorkspaceConnectionsLoading ||
      userCoreContactLoading ||
      workAccountsLoading ||
      workspacesLoading
    )
  }, [
    workAccountWorkspaceConnectionsLoading,
    userCoreContactLoading,
    workAccountsLoading,
    workspacesLoading,
  ])

  const value = useMemo(() => {
    return {
      userCoreContact,
      refetchUserCoreContact,
      workAccounts,
      setWorkAccounts,
      refetchWorkAccounts,
      workspaces,
      setWorkspaces,
      refetchWorkspaces,
      workAccountWorkspaceConnections,
      refetchWorkAccountWorkspaceConnections,
      sidebarObject,
      setSidebarObject,
      selectedWorkspace,
      setSelectedWorkspace,
      people,
      refetchPeople,
      internalDomains,
      suggestedExclusions,
      refetchSuggestedExclusions,
      loading,
      selectedWorkspaceLoading,
      workspacePeople,
      workspaceOrganizations,
      refetchWorkspaceOrganizations,
      orgsByDomain,
      peopleByEmail,
      pipelines,
      refetchPipelines,
    }
  }, [
    userCoreContact,
    workAccounts,
    workspaces,
    workAccountWorkspaceConnections,
    sidebarObject,
    selectedWorkspace,
    workspacePeople,
    people,
    refetchPeople,
    internalDomains,
    selectedWorkspaceLoading,
    suggestedExclusions,
    refetchSuggestedExclusions,
    refetchWorkAccountWorkspaceConnections,
    refetchUserCoreContact,
    refetchWorkspaceOrganizations,
    refetchWorkspaces,
    refetchWorkAccounts,
    refetchPipelines,
    peopleByEmail,
    pipelines,
    orgsByDomain,
    workspaceOrganizations,
    loading,
  ])

  return <DayContext.Provider value={value}>{children}</DayContext.Provider>
}
