import { routes } from '@redwoodjs/router'

export const statusLabels = {
  JOINING_CALL: 'Joining call',
  IN_WAITING_ROOM: 'In waiting room',
  IN_CALL_NOT_RECORDING: 'In call, not recording',
  RECORDING_PERMISSION_ALLOWED: 'Recording permission allowed',
  RECORDING_PERMISSION_DENIED: 'Recording permission denied',
  IN_CALL_RECORDING: 'In call, recording',
  PREPARING_RECORDING: 'Preparing recording',
  CALL_ENDED_WITHOUT_RECORDING: 'Call ended without recording',
  RECORDING_ERRORED: 'Recording errored',
  READY: 'Ready',
}

export const statusCategories = {
  PENDING: 'pending',
  FAILED: 'failed',
  READY: 'ready',
}

export const statusMetadata = {
  JOINING_CALL: {
    label: 'Joining call',
    shortLabel: 'Joining',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation:
      'The recording bot is currently in the process of joining the meeting call. Please wait until it successfully joins.',
  },
  IN_WAITING_ROOM: {
    label: 'In waiting room',
    shortLabel: 'Waiting room',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation:
      "The recording bot is in the meeting's waiting room. An attendee or host needs to grant access to the bot to start recording.",
  },
  IN_CALL_NOT_RECORDING: {
    label: 'In call, not recording',
    shortLabel: 'Waiting in call',
    severity: 'warning',
    category: statusCategories.PENDING,
    explanation:
      "The bot is in the call but hasn't started recording yet. It might be waiting for permission to record or encountering issues.",
  },
  RECORDING_PERMISSION_ALLOWED: {
    label: 'Recording permission allowed',
    shortLabel: 'Recording allowed',
    severity: 'success',
    category: statusCategories.PENDING,
    explanation:
      'The host has granted the recording bot permission to start recording the meeting.',
  },
  RECORDING_PERMISSION_DENIED: {
    label: 'Recording permission denied',
    shortLabel: 'Recording denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The recording bot was denied permission to record the meeting. No recording will be made.',
  },
  IN_CALL_RECORDING: {
    label: 'In call, recording',
    shortLabel: 'Recording now',
    severity: 'success',
    category: statusCategories.PENDING,
    explanation: 'The meeting is currently being recorded by the bot.',
  },
  PREPARING_RECORDING: {
    label: 'Preparing recording',
    shortLabel: 'Preparing',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation:
      'The recording of the meeting has ended and is now being prepared for review.',
  },
  CALL_ENDED_WITHOUT_RECORDING: {
    label: 'Call ended without recording',
    shortLabel: 'Did not record',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The call has ended, but no recording was made. This could be due to various reasons such as permission issues or technical difficulties.',
  },
  RECORDING_ERRORED: {
    label: 'Recording errored',
    shortLabel: 'Recording errored',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'An error occurred during the recording process, preventing the meeting from being recorded.',
  },
  PREPARING_TRANSCRIPT: {
    label: 'Preparing the meeting transcript',
    shortLabel: 'Preparing transcript',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation: 'Day.ai is preparing the transcript for this recording.',
  },
  TRANSCRIPT_ERRORED: {
    label: 'Error creating & retrieving the transcript for this meeting',
    shortLabel: 'Transcript errored',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'An error occurred while preparing the transcript for this recording.',
  },
  PREPARING_SUMMARY: {
    label: 'Preparing meeting notes & summary',
    shortLabel: 'Preparing summary',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation: 'Day.ai is preparing the summary for this recording.',
  },
  SUMMARY_ERRORED: {
    label: 'Error creating notes & summary for this meeting',
    shortLabel: 'Summary errored',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'An error occurred while preparing the summary for this recording.',
  },
  READY: {
    label: 'Ready',
    shortLabel: 'Ready',
    severity: 'success',
    category: statusCategories.READY,
    explanation:
      'The recording is ready for review. You can now access and review the meeting recording.',
  },
  HOST_DENIED_PERMISSION: {
    label: 'Host denied permission to the Day.ai Assistant',
    shortLabel: 'Host denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The recording didn't happen because the host explicitly denied the permission to record the meeting.",
  },
  HOST_DID_NOT_GRANT_PERMISSION: {
    label: 'Host did not grant permission to the Day.ai Assistant',
    shortLabel: 'Host did not grant',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The recording didn't occur because the host did not grant permission to record, different from an explicit denial.",
  },
  FAILED_TO_START_RECORDING: {
    label: 'A technical issue caused the meeting to fail to be recorded',
    shortLabel: 'Failed to record',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The recording was permitted but failed to start due to technical issues or unspecified reasons.',
  },
  CALL_ENDED_BY_PLATFORM_WAITING_ROOM_TIMEOUT: {
    label: 'Day.ai Assistant was not allowed into the meeting',
    shortLabel: 'Bot not allowed',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The call ended without recording because the host/admin did not allow the bot in from the waiting room.',
  },
  BOT_KICKED_FROM_WAITING_ROOM: {
    label:
      'Day.ai Assistant was removed from waiting room by the host or admin',
    shortLabel: 'Bot kicked',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The recording bot was kicked from the meeting waiting room before it could join the call.',
  },
  UNSPECIFIED_ISSUE: {
    label: 'Unspecified issue',
    shortLabel: 'Unspecified',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation: 'The call ended without recording.',
  },
  ZOOM_SDK_APP_NOT_PUBLISHED: {
    label: 'SDK app not published',
    shortLabel: 'SDK not published',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The Zoom SDK app required for the recording bot is not published, preventing the bot from functioning properly.',
  },
  ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST: {
    label: 'Local recording request denied by host',
    shortLabel: 'Host issue',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The host has denied the request for local recording, preventing the bot from capturing the meeting.',
  },
  TIMEOUT_EXCEEDED_EVERYONE_LEFT: {
    label: 'Timeout exceeded, everyone left',
    shortLabel: 'Waited & left',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting ended due to a timeout as all participants left, and no recording was made.',
  },
  ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED: {
    label: 'Local recording grant not  supported',
    shortLabel: 'Not supported',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The current meeting settings do not support granting permission for local recording.',
  },
  TIMEOUT_EXCEEDED_RECORDING_PERMISSION_DENIED: {
    label: 'Timeout exceeded, recording denied',
    shortLabel: 'Host denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting ended due to a timeout after recording permission was denied, resulting in no recording.',
  },
  TIMEOUT_EXCEEDED_IN_CALL_NOT_RECORDING: {
    label: 'Timeout exceeded in call, not recording',
    shortLabel: 'Waited & left',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting ended without the bot being given permission to record.',
  },
  ZOOM_LOCAL_RECORDING_DISABLED: {
    label: 'Local recording disabled',
    shortLabel: 'Host issue',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'Local recording is disabled in the meeting settings, preventing the bot from recording.',
  },
  ZOOM_HOST_NOT_PRESENT: {
    label: 'Host not present',
    shortLabel: 'Host not present',
    severity: 'warning',
    category: statusCategories.FAILED,
    explanation:
      'The meeting is ongoing without the host present, which may affect recording capabilities.',
  },
  TIMEOUT_EXCEEDED_ONLY_BOTS_DETECTED_USING_PARTICIPANT_EVENTS: {
    label: 'Timeout exceeded, only bots detected',
    shortLabel: 'Only bots joined',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting ended after a timeout as only bots were detected using participant events, leading to no recording.',
  },
  TIMEOUT_EXCEEDED_WAITING_ROOM: {
    label: 'The bot waited in the waiting room as long as it could',
    shortLabel: 'Waited for host',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting ended due to a timeout while the bot was in the waiting room, preventing it from joining the call.',
  },
}

export const addMeetingRecordingStatus = ({ meetingRecordings }) => {
  return meetingRecordings.map((recording) => {
    const finalState = computeFinalMetaState({ meetingRecording: recording })
    return {
      status: finalState,
      tab: statusMetadata[finalState].category,
      severity: statusMetadata[finalState].severity,
      ...recording,
    }
  })
}

export const getMeetingRecordingStatus = ({ meetingRecording }) => {
  const state = computeFinalMetaState({ meetingRecording })
  return statusMetadata[state]
}

export const computeFinalMetaState = ({ meetingRecording }) => {
  const statusHistory =
    meetingRecording?.statusHistory?.length > 0
      ? [...meetingRecording.statusHistory]
      : []

  if (statusHistory.length === 0) {
    return 'UNSPECIFIED_ISSUE'
  }
  // Sort the statusHistory array by createdAt timestamp in descending order to get the most recent event first
  statusHistory.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  // Check the most recent status
  const mostRecentStatus = statusHistory[0].status
  const mostRecentEvent = statusHistory[0]

  if (
    [
      'CALL_ENDED_BY_PLATFORM_WAITING_ROOM_TIMEOUT',
      'ZOOM_SDK_APP_NOT_PUBLISHED',
      'ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST',
      'TIMEOUT_EXCEEDED_EVERYONE_LEFT',
      'ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED',
      'TIMEOUT_EXCEEDED_RECORDING_PERMISSION_DENIED',
      'TIMEOUT_EXCEEDED_IN_CALL_NOT_RECORDING',
      'TIMEOUT_EXCEEDED_WAITING_ROOM',
      'ZOOM_LOCAL_RECORDING_DISABLED',
      'ZOOM_HOST_NOT_PRESENT',
      'TIMEOUT_EXCEEDED_ONLY_BOTS_DETECTED_USING_PARTICIPANT_EVENTS',
      'BOT_KICKED_FROM_WAITING_ROOM',
    ].includes(mostRecentEvent?.reason) &&
    mostRecentStatus != 'PREPARING_RECORDING'
  ) {
    return mostRecentEvent?.reason
  }

  if (mostRecentStatus === 'PREPARING_RECORDING') {
    if (meetingRecording?.transcript?.status) {
      if (meetingRecording?.transcript?.status === 'PREPARING') {
        return 'PREPARING_TRANSCRIPT'
      }
      if (meetingRecording?.transcript?.status === 'ERRORED') {
        return 'TRANSCRIPT_ERRORED'
      }
      if (meetingRecording?.summary?.status === 'READY') {
        if (meetingRecording?.summary?.status === 'PREPARING') {
          return 'PREPARING_SUMMARY'
        }
        if (meetingRecording?.summary?.status === 'ERRORED') {
          return 'SUMMARY_ERRORED'
        }
        // summaryStatus === 'READY' should mean overall status is READY
      }
    }
  }

  // If the most recent status is not 'CALL_ENDED_WITHOUT_RECORDING', return it directly
  // this includes 'PREPARING_RECORDING' and 'READY'
  if (mostRecentStatus !== 'CALL_ENDED_WITHOUT_RECORDING') {
    return mostRecentStatus
  }

  // If the call ended without recording, determine the appropriate meta state
  // Check for specific conditions in the history
  const permissionDenied = statusHistory.some(
    (event) => event.status === 'RECORDING_PERMISSION_DENIED'
  )
  const permissionAllowed = statusHistory.some(
    (event) => event.status === 'RECORDING_PERMISSION_ALLOWED'
  )
  const inCallNotRecording = statusHistory.some(
    (event) => event.status === 'IN_CALL_NOT_RECORDING'
  )
  const inCallRecording = statusHistory.some(
    (event) => event.status === 'IN_CALL_RECORDING'
  )

  // Determine meta state based on conditions
  if (permissionDenied) {
    return 'HOST_DENIED_PERMISSION'
  } else if (!permissionAllowed && inCallNotRecording) {
    return 'HOST_DID_NOT_GRANT_PERMISSION'
  } else if (permissionAllowed && !inCallRecording) {
    return 'FAILED_TO_START_RECORDING'
  } else {
    // Other specific logic or default meta state can be added here
    return 'UNSPECIFIED_ISSUE' // A fallback state, adjust as necessary
  }
}

export const videoTitle = (meetingRecording) => {
  const finalState = computeFinalMetaState({ meetingRecording })
  if (statusMetadata[finalState].category === statusCategories.READY) {
    return (
      meetingRecording.calendarEvents[0]?.GoogleEvent?.title ||
      meetingRecording.title
    )
  } else {
    return `"${meetingRecording.title}" - ${statusMetadata[finalState].label}`
  }
}

export const videoSubtitle = (meetingRecording) => {
  const finalState = computeFinalMetaState({ meetingRecording })
  if (statusMetadata[finalState].category === statusCategories.READY)
    return (
      meetingRecording.summary?.output?.Suggested_Title ||
      meetingRecording.title
    )
  else return statusMetadata[finalState].explanation
}

export const recordingLink = ({ workspaceId = null, meetingRecording }) => {
  const finalState = computeFinalMetaState({ meetingRecording })
  if (finalState === 'READY') {
    if (workspaceId) {
      return routes.workspaceMeetingRecording({
        workspaceId,
        recordingId: meetingRecording.id,
      })
    } else {
      return routes.reviewMeeting({ id: meetingRecording.id })
    }
  } else {
    if (workspaceId) {
      return routes.workspaceMeetingRecordingAudit({
        workspaceId,
        recordingId: meetingRecording.id,
      })
    } else {
      return routes.recordingAudit({ id: meetingRecording.id })
    }
  }
}
