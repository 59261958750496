import { useContext } from 'react'

import { Avatar, Chip } from '@mui/material'

import { DayContext } from 'src/lib/dayContext'

const WorkspaceMemberChip = ({ userId }: { userId: string }) => {
  const { selectedWorkspace, workspaces } = useContext(DayContext)
  const workspace = workspaces.find((w) => w.id === selectedWorkspace)
  const member = workspace?.members.find((m) => m.id === userId)
  return (
    <Chip
      label={`${member?.coreContact?.firstName} ${member?.coreContact?.lastName}`}
      size="small"
      avatar={<Avatar src={member?.coreContact?.photo} />}
      variant="outlined"
    />
  )
}

export default WorkspaceMemberChip
