import { useContext, useMemo, useState } from 'react'

import { Chip, Menu, MenuItem } from '@mui/material'
import toast from 'react-hot-toast'

import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

import { getStageStyle } from '../Pipeline/Pipeline'

const UPDATE_OPPORTUNITY_STAGE_FROM_CHIP = gql`
  mutation UpdateOpportunityStageFromChip(
    $opportunityId: String!
    $stageId: String!
    $workspaceId: String!
  ) {
    updateOpportunityStage(
      opportunityId: $opportunityId
      stageId: $stageId
      workspaceId: $workspaceId
    )
  }
`

const StageChip = ({
  stage,
  height = '36px',
  allowChange = false,
  stages = [],
  opportunityId = null,
  onUpdate = () => {},
  sx = {},
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const [updateOpportunityStageFromChip] = useMutation(
    UPDATE_OPPORTUNITY_STAGE_FROM_CHIP
  )

  const orderedStages = useMemo(() => {
    if (!stages) return []
    return stages.filter(Boolean).sort((a, b) => a.position - b.position)
  }, [stages])

  const handleUpdateStage = async (newStage) => {
    toast.promise(
      updateOpportunityStageFromChip({
        variables: {
          opportunityId,
          stageId: newStage.id,
          workspaceId: selectedWorkspace,
        },
      }),
      {
        loading: 'Updating stage...',
        success: () => {
          handleClose()
          onUpdate()
          return 'Stage updated successfully'
        },
        error: 'Failed to update stage',
      }
    )
  }

  const handleClick = (event) => {
    if (allowChange) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    stage && (
      <>
        <Chip
          label={stage?.title}
          clickable={allowChange}
          onClick={handleClick}
          sx={{
            ...getStageStyle(stage?.position),
            fontSize: '12px',
            height: height,
            fontWeight: 600,
            letterSpacing: '-0.2px',
            borderRadius: '4px',
            ...sx,
          }}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {orderedStages.map((stageOption) => (
            <MenuItem
              key={stageOption.id}
              onClick={() => handleUpdateStage(stageOption)}
            >
              <StageChip stage={stageOption} />
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  )
}

export default StageChip
