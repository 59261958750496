import { useContext, useMemo } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

import SidebarActions from '../../Sidebar/SidebarActions/SidebarActions'

const GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR = gql`
  query GetActionsForOpportunitySidebar(
    $opportunityId: String!
    $workspaceId: String!
  ) {
    actionsForOpportunity(
      opportunityId: $opportunityId
      workspaceId: $workspaceId
    ) {
      id
      title
      status {
        id
        label
        updatedAt
      }
      owner {
        id
        email
      }
      channel {
        id
        label
        accountId
        type
      }
      draft {
        id
        title
        body
      }
      people
      organizations
      opportunityIds
      createdAt
    }
  }
`

const OpportunityActions = ({
  opportunityId,
  actions,
}: {
  opportunityId: string
  actions: any[]
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch, loading } = useQuery(
    GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR,
    {
      variables: {
        opportunityId,
        workspaceId: selectedWorkspace,
      },
      skip: !selectedWorkspace || !opportunityId,
    }
  )
  const finalActions = useMemo(
    () => data?.actionsForOpportunity || actions || [],
    [data, actions]
  )

  return Array.isArray(finalActions) && finalActions?.length > 0 ? (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h2">Actions</Typography>
      <SidebarActions
        actions={finalActions}
        onUpdate={refetch}
        opportunityId={opportunityId}
      />
    </Box>
  ) : null
}

export default OpportunityActions
