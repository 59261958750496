import { NodeApi } from 'react-arborist'
import { IdObj } from 'react-arborist/dist/module/types/utils'
import { useCallback, useMemo } from 'react'
import { logger } from 'src/lib/logger'
import { useConfirm } from 'material-ui-confirm'

interface TreeData {
  id: string
  name: string
  emoji?: string
  children?: TreeData[]
}

class TreeNode<T extends TreeData> {
  id: string
  name: string
  emoji?: string
  children?: TreeNode<T>[]

  constructor(public data: T, public parent: TreeNode<T> | null) {
    this.id = data.id
    this.name = data.name
    this.emoji = data.emoji
    this.parent = parent
    this.children = data.children?.map((child) => new TreeNode<T>(child, this))
  }

  isLeaf(): boolean {
    return !this.children || this.children.length === 0
  }

  hasParent(): boolean {
    return !!this.parent
  }

  get childIndex(): number {
    return this.hasParent() ? this.parent.children!.indexOf(this) : -1
  }

  addChild(
    node: TreeNode<T>,
    index: number = this.children?.length ?? 0
  ): void {
    if (!this.children) {
      this.children = []
    }

    if (!this.data.children) {
      this.data.children = []
    }

    node.parent = this

    this.children.splice(index, 0, node)
    this.data.children.splice(index, 0, node.data)
  }

  removeChild(index: number): void {
    this.children?.splice(index, 1)
    this.data.children?.splice(index, 1)
  }

  drop() {
    if (this.hasParent()) {
      const index = this.childIndex
      if (index > -1) {
        this.parent.children.splice(index, 1)
        this.parent.data.children.splice(index, 1)
      }
      this.parent = null // After removal, set parent to null
    }
  }
}

export type CreateHandler<T> = (args: {
  parentId: string | null
  parentNode: NodeApi<T> | null
  index: number
  type: 'internal' | 'leaf'
}) => (IdObj | null) | Promise<IdObj | null>

export type MoveHandler<T> = (args: {
  dragIds: string[]
  dragNodes: NodeApi<T>[]
  parentId: string | null
  parentNode: NodeApi<T> | null
  index: number
}) => void | Promise<void>

export type RenameHandler<T> = (args: {
  id: string
  name: string
  node: NodeApi<T>
}) => void | Promise<void>

export type DeleteHandler<T> = (args: {
  ids: string[]
  nodes: NodeApi<T>[]
}) => void | Promise<void>

export type EditResult =
  | { cancelled: true }
  | { cancelled: false; value: string }

export type TreeNodeData = {
  id: string
  name: string
  children?: TreeNodeData[]
}

class TreeManager<T extends TreeData> {
  root: TreeNode<T>

  constructor(treeData: T) {
    this.root = new TreeNode<T>(treeData, null)
  }

  get data() {
    return this.root.children?.map((node) => node.data) ?? []
  }

  findNode(id: string, node: TreeNode<T> = this.root): TreeNode<T> | null {
    if (node.id === id) return node
    if (node.children) {
      for (const child of node.children) {
        const found = this.findNode(id, child)
        if (found) return found
      }
    }
    return null
  }

  removeNode(nodeId: string): boolean {
    const nodeToRemove = this.findNode(nodeId)
    logger.dev({ nodeToRemove })
    if (nodeToRemove && nodeToRemove.parent) {
      nodeToRemove.parent.removeChild(nodeToRemove.childIndex)
      return true
    }
    return false
  }

  insertNode(
    parentId: string,
    nodeToInsert: TreeNode<T>,
    index: number
  ): boolean {
    const parentNode = this.findNode(parentId)
    if (parentNode) {
      parentNode.addChild(nodeToInsert, index)
      return true
    }
    return false
  }

  moveNode(dragId: string, parentId: string, index: number): boolean {
    if (!parentId) parentId = 'root'

    const nodeToMove = this.findNode(dragId)
    const parentNode = this.findNode(parentId) || this.root

    if (!parentNode || !nodeToMove) {
      logger.dev('Not moving: Node or parent not found')
      return false
    }

    // Check if the node is moved within the same parent
    const isSameParent = nodeToMove.parent && nodeToMove.parent === parentNode

    if (nodeToMove.hasParent()) {
      const currentIndex = nodeToMove.childIndex
      if (currentIndex > -1) {
        nodeToMove.parent.children.splice(currentIndex, 1)
        if (nodeToMove.parent.data.children) {
          nodeToMove.parent.data.children.splice(currentIndex, 1)
        }
        // Adjust the index for downward movement within the same parent
        if (isSameParent && currentIndex < index) {
          index-- // Decrement the target index because the array length has reduced by one
        }
      }
    }

    parentNode.addChild(nodeToMove, index)

    return true
  }

  updateNode(id: string, changes: Partial<T>): boolean {
    const node = this.findNode(id)
    if (node) {
      Object.assign(node, changes)
      return true
    }
    return false
  }

  renameNode(id: string, newName: string): boolean {
    const node = this.findNode(id)
    if (node) {
      node.name = newName
      node.data.name = newName
      logger.dev({ node })
      return true
    }
    return false
  }

  drop(id: string) {
    const node = this.findNode(id)
    if (node) node.drop()
  }
}

export function useTreeManager(data, onUpdate = (treeData) => {}) {
  const confirm = useConfirm()

  const tree = useMemo(() => {
    if (!data)
      return new TreeManager({ id: 'root', name: 'Root', children: [] })

    return new TreeManager({
      id: 'root',
      name: 'Root',
      children: data ? data?.map((d) => new TreeNode(d, null)) : [],
    })
  }, [data])

  const onMove = useCallback(
    ({ dragIds, parentId, index }) => {
      dragIds.forEach((dragId) => {
        tree.moveNode(dragId, parentId, index)
      })
      logger.dev({ oldData: data, newData: tree.data })
      onUpdate(tree.data)
    },
    [data, tree, onUpdate]
  )

  const onRename = useCallback(
    ({ id, name }) => {
      tree.renameNode(id, name)
      onUpdate(tree.data)
    },
    [tree, onUpdate]
  )

  const onCreate = useCallback(
    ({ parentId, node, index = 0, parentNode = null }) => {
      const newNode = tree.insertNode(
        parentId,
        new TreeNode(node, parentNode),
        index
      )
      onUpdate(tree.data)
      return newNode
    },
    [tree, onUpdate]
  )

  const onDelete = useCallback(
    ({ id, nodes }) => {
      if (['USERS', 'OTHERS'].includes(id)) {
        return
      }
      confirm({ description: 'Are you sure you want to delete this folder?' })
        .then(() => {
          tree.removeNode(id)
          onUpdate(tree.data)
        })
        .catch(() => {})
    },
    [tree, onUpdate, confirm]
  )

  const findNode = useCallback(
    (id) => {
      return tree.findNode(id)
    },
    [tree]
  )

  return { onMove, onRename, onCreate, onDelete, findNode }
}
