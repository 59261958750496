import { useContext, useMemo, useState } from 'react'

import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  Typography,
} from '@mui/material'
import { RiCloseLine } from '@remixicon/react'

import ContactTile from 'src/components/ContactTile/ContactTile'
import ObjectFinder from 'src/components/ObjectFinder/ObjectFinder'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import OrganizationTile from '../../Organizations/OrganizationTile/OrganizationTile'

const OpportunityFinder = ({
  selectedOrg,
  selectedPerson,
  onSelect,
  pipelineType = null,
  readOnly = false,
}) => {
  const { workspaceOrganizations } = useContext(DayContext)
  const [query, setQuery] = useState('')
  const [selectedDomain, setSelectedDomain] = useState(
    selectedOrg?.domain || ''
  )
  const [selectedEmail, setSelectedEmail] = useState(
    selectedPerson?.email || ''
  )

  const isValidDomain =
    /^(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})|(?:\d{1,3}(?:\.\d{1,3}){3}))$/.test(
      selectedDomain
    )

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selectedEmail)

  const isValidInput = isValidDomain || isValidEmail

  const handleQueryUpdate = (newValue) => {
    setQuery(newValue)
  }

  const suggestedDomains = useMemo(() => {
    const workspaceDomains = workspaceOrganizations
      .filter((org) => {
        const orgType = org?.lifecycle?.pipelineType

        if (orgType === pipelineType) {
          return true
        }
        return false
      })
      .map((org) => org.domain)
      .filter(Boolean)
    return [...new Set([...workspaceDomains])]
  }, [pipelineType])

  if (selectedOrg) {
    return (
      <Box>
        <OrganizationTile
          domain={selectedOrg.domain}
          openSidebar={false}
        />
      </Box>
    )
  }

  if (selectedPerson) {
    return (
      <Box>
        <ContactTile
          email={selectedPerson.objectId}
          showSidebar={false}
        />
      </Box>
    )
  }

  return (
    !readOnly && (
      <>
        <Box sx={{ height: 'calc(674px - 100px)' }}>
          {selectedEmail && (
            <Row sx={{ justifyContent: 'space-between' }}>
              <ContactTile
                email={selectedEmail}
                showSidebar={false}
              />
              <IconButton onClick={() => setSelectedEmail('')}>
                <RiCloseLine />
              </IconButton>
            </Row>
          )}
          {selectedDomain && (
            <Row sx={{ justifyContent: 'space-between' }}>
              <OrganizationTile
                domain={selectedDomain}
                openSidebar={false}
              />
              <IconButton onClick={() => setSelectedDomain('')}>
                <RiCloseLine />
              </IconButton>
            </Row>
          )}
          {!selectedEmail && !selectedDomain && (
            <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
              <ObjectFinder
                freeSolo={true}
                onSelect={(result) => {
                  if (result.objectType === NativeObjectTypes.Contact) {
                    setSelectedEmail(result.objectId)
                  } else if (
                    result.objectType === NativeObjectTypes.Organization
                  ) {
                    setSelectedDomain(result.objectId)
                  }
                }}
                placeholder="Search for an organization or person"
                textFieldSx={{
                  px: 0,
                  mx: 0,
                }}
                objectTypes={[
                  NativeObjectTypes.Contact,
                  NativeObjectTypes.Organization,
                ]}
                distance={0}
                onChangeQuery={handleQueryUpdate}
              />
              {query?.length === 0 && suggestedDomains.length > 0 && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h4">Suggested Opportunities</Typography>
                  <List>
                    {suggestedDomains.map((domain) => (
                      <ListItemButton
                        key={`suggested-opp-domain-${domain}`}
                        sx={{ py: 2 }}
                        onClick={() => {
                          setSelectedDomain(domain)
                        }}
                      >
                        <OrganizationTile
                          domain={domain}
                          openSidebar={false}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          )}
          {(selectedEmail || selectedDomain) && (
            <Button
              variant="outlined"
              color="secondary"
              disabled={!isValidInput}
              disableElevation={true}
              fullWidth={true}
              onClick={() => {
                onSelect({
                  domain: selectedDomain,
                  email: selectedEmail,
                })
              }}
              sx={{ mt: 2 }}
            >
              Select
            </Button>
          )}
        </Box>
      </>
    )
  )
}

export default OpportunityFinder
