import { useContext } from 'react'

import { Box, Chip } from '@mui/material'
import { Opportunity } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../DomainAvatar/DomainAvatar'

const OpportunityChip = ({
  opportunity,
  size = 'small',
  width = '192px',
}: {
  opportunity: Opportunity
  size?: 'small' | 'medium'
  width?: string
}) => {
  const { setSidebarObject } = useContext(DayContext)

  if (!opportunity) {
    return null
  }

  const revenue = opportunity.expectedRevenue
    ? ` - $${opportunity.expectedRevenue.toLocaleString()}`
    : ''
  const icon = opportunity.domain ? (
    <Box
      sx={{
        pl: size === 'small' ? '4px' : '6px',
        mr: '4px',
        overflow: 'hidden',
        height: size === 'small' ? '16px' : '18px',
        width: size === 'small' ? '16px' : '18px',
      }}
    >
      <DomainAvatar
        domain={opportunity.domain}
        size={size === 'small' ? 16 : 18}
      />
    </Box>
  ) : null
  return (
    opportunity && (
      <Chip
        label={`${opportunity.title}${revenue}`}
        size={size}
        variant="outlined"
        icon={icon}
        onClick={() =>
          setSidebarObject({
            objectType: NativeObjectTypes.Opportunity,
            objectId: opportunity.id,
            properties: opportunity,
          })
        }
        sx={{
          maxWidth: '264px',
          width: width,
          fontWeight: 600,
          letterSpacing: '-0.2px',
          justifyContent: 'flex-start',
        }}
      />
    )
  )
}

export default OpportunityChip
