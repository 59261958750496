import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  lighten,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import {
  IconAlertSquareFilled,
  IconBrain,
  IconCheck,
  IconChevronLeft,
  IconPlus,
  IconThumbUpFilled,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { isInternalUser } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { mergeObjects } from 'src/lib/objects'
import {
  NativeSuggestedPipelineTypes,
  ValidPipelineSetupStages,
} from 'src/lib/relationshipSummary'

import Row from '../../Row/Row'

const CREATE_NEW_PIPELINE_INTERACTIVE = gql`
  mutation CreateNewPipelineInteractive($input: PipelineCreateInput!) {
    createPipeline(input: $input) {
      id
      type
      title
      setupSteps
    }
  }
`

const UPDATE_PIPELINE_INTERACTIVE = gql`
  mutation UpdatePipelineInteractive(
    $id: String!
    $input: PipelineUpdateInput!
  ) {
    updatePipeline(id: $id, input: $input) {
      id
      type
      title
      setupSteps
    }
  }
`

const SETUP_PIPELINE_INTERACTIVE = gql`
  mutation SetupPipelineInteractive(
    $id: String!
    $workspaceId: String!
    $setupSteps: [JSON!]!
  ) {
    setupPipeline(id: $id, workspaceId: $workspaceId, setupSteps: $setupSteps) {
      key
      confidence
      missingInformation
    }
  }
`

const GET_PIPELINE_INTERACTIVE = gql`
  query GetPipelineInteractive($id: String!, $workspaceId: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      type
      title
      setupSteps
      icp {
        organization
        metadata
        people
      }
    }
  }
`

interface EffectOnPipeline {
  stageType: ValidPipelineSetupStages
  outputFields: (
    | 'title'
    | 'entranceCriteria'
    | 'icpPeople'
    | 'icpOrganization'
    | 'icpMetadata'
    | 'description'
  )[]
}

interface Step {
  id: string
  goal: string
  title: string
  prompt: string
  examplePrompts: string[]
  userResponse?: string
  sufficient?: boolean
  confidence?: number
  notesToUser?: string
  examplesOpen?: boolean
  effectOnPipeline?: EffectOnPipeline[]
  changed?: boolean
}

const testResponses = [
  {
    id: 'firmographics',
    response:
      'B2B Technology or Professional Services, North America, 100-1000 employees',
  },
  {
    id: 'roles',
    response:
      'Ceo, founder, co-founder, COO, Chief of Staff, sales manager, marketing manager',
  },
  {
    id: 'discovery',
    response: `We need to learn if the prospect has an offering in the market that they are actively selling\n
      Important to know ... do they have a CRM they use today, and if so is there a full-time person using it? If they do have a full time CRM person, they are not likely a fit for us\n
      Also: how defined is their sales process?`,
  },
  {
    id: 'evaluation',
    response: `We will provide a very brief demo and then focus on onboarding during the first meeting\n
      Before the meeting we will send instructions on how to set up their workspace and begin syncing their Gmail account, as well as set up meeting recording default settings\n
      Once the demo is complete and at least one user is active in the workspace, and their data is processed and their pipeline is set up with at least four active opportunities, we consider them in an "Active Trial".\n
      This phase is where the prospect will do most of the evaluating of our product, leading to a willingness to commit and pay.`,
  },
  {
    id: 'making-the-decision',
    response: `Once the prospect's team is in the workspace and generally active for a week or so, we need to reach out to them and schedule another meeting to collect feedback. If we see that the prospect and their team is relying on our CRM (as evidenced by them having no backup/alternate opportunity-management system), we will ask them if they are ready to move forward and become a paying customer.\n
    Once a prospect says they are ready to pay for the product, we need to send them a quote and payment link.`,
  },
  {
    id: 'closed-won',
    response:
      'We only consider a deal closed-won once we have received payment via Stripe Billing.',
  },
]

const salesSteps: Step[] = [
  {
    id: 'firmographics',
    goal: 'Targeting Accounts',
    title: 'Firmographics',
    prompt:
      'What makes an organization a fit for your product or service? Size of company, industry, region/location, etc',
    examplePrompts: [
      'Our target is companies with 100-1000 employees who sell B2B SaaS',
      'Ideal customer profile: companies in the US that are in the healthcare industry',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata'],
      },
    ],
  },
  {
    id: 'connection',
    goal: 'Finding a champion',
    title: 'Person-level ICP',
    prompt:
      "What is your ideal prospect's seniority, function/department, and how do you start the relationship (email reply, book intro call, etc)?",
    examplePrompts: [
      'We book introductory calls with Marketing Managers, Director of Marketing, etc',
      'We try to get email replies from cold emails to a VP of Sales, Director of Sales, Senior Sales Manager, etc',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpPeople'],
      },
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria'],
      },
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
  {
    id: 'discovery',
    goal: 'Tailoring the deal',
    title: 'Discovery & Qualification',
    prompt:
      'How do you learn what your prospects really need? What steps do you take to understand their problems before offering solutions?',
    examplePrompts: [
      'We need to learn if the prospect has a need to generate leads, and if they do we consider them qualified and worth pursuing',
      'If the prospect already has a video security system, we need to learn if they are happy with their current provider and if they are likely to switch',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title'],
      },
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
  {
    id: 'evaluation',
    goal: 'Kicking the tires',
    title: 'Evaluation',
    prompt:
      'How do prospects evaluate your product? Do people get a demo (or several), enter a free trial, or conduct a paid pilot, a kickoff service engagement ... or something else?',
    examplePrompts: [
      'We need to learn if the prospect has a need to generate leads, and if they do we consider them qualified and worth giving a demo.',
      'If the prospect already has a video security system, we need to learn if they are happy with their current provider and if they are likely to switch.',
      'Prospects enter a free trial themselves when we give them access, post-qualification',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['entranceCriteria', 'title'],
      },
    ],
  },
  {
    id: 'making-the-decision',
    goal: 'Getting to yes',
    title: 'Negotiation & Decision',
    prompt:
      'What does a prospect receive that they can "say yes to"? Do they get a quote, a payment link, or something else?',
    examplePrompts: [
      'We send a quote and when we get a verbal commit, we send a payment link to the prospect and they click through and pay from there',
      'People view our pricing page and then buy with a credit card when they are ready to go',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'closed-won',
    goal: 'Ringing the bell',
    title: 'Closing Won',
    prompt: 'What is the signal that a deal is closed and won?',
    examplePrompts: [
      'We see a payment in Stripe',
      'Once the contract is signed, we know the deal is for sure won',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
]

// Fundraising Pipeline Steps
const fundraisingSteps: Step[] = [
  {
    id: 'investor-targeting',
    goal: 'Targeting Investors',
    title: 'Investor Fit',
    prompt:
      'What makes an investor a good fit for your round? Investment stage, sector focus, check size, geography, etc.',
    examplePrompts: [
      'We are targeting Seed-stage VCs who invest $1-3M in B2B SaaS companies',
      'Looking for Series A investors with healthcare expertise in the US East Coast',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata', 'icpPeople'],
      },
    ],
  },
  {
    id: 'warm-intro',
    goal: 'Getting Introductions',
    title: 'Warm Introduction',
    prompt:
      'How do you get introduced to investors? Through network, accelerator, investment platform?',
    examplePrompts: [
      'We get intros from our angel investors to their VC connections',
      'Our accelerator program makes direct introductions to their partner funds',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'first-meeting',
    goal: 'Initial Partner Meeting',
    title: 'First Partner Meeting',
    prompt: 'What needs to happen in the first meeting to move forward?',
    examplePrompts: [
      'Share company vision and traction metrics with investing partner',
      'Demonstrate product-market fit and growth trajectory',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'due-diligence',
    goal: 'Due Diligence',
    title: 'Due Diligence',
    prompt: 'What materials and meetings are needed for due diligence?',
    examplePrompts: [
      'Share data room access with financial statements, contracts, and metrics',
      'Schedule team meetings and customer references',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'term-sheet',
    goal: 'Term Sheet Negotiation',
    title: 'Term Sheet',
    prompt: 'What are the key terms to negotiate and agree upon?',
    examplePrompts: [
      'Negotiate valuation, investment amount, and key terms with lead investor',
      'Review and agree on governance rights and board composition',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'closing',
    goal: 'Legal Closing',
    title: 'Closing',
    prompt: 'What defines a successfully closed investment?',
    examplePrompts: [
      'All documents signed and money transferred to company account',
      'Cap table updated and shares issued to new investors',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
]

// Upsell Pipeline Steps
const upsellSteps: Step[] = [
  {
    id: 'champion-alignment',
    goal: 'Champion Engagement',
    title: 'Champion Alignment',
    prompt:
      'How do you engage with existing champions for expansion opportunities?',
    examplePrompts: [
      'Quarterly business review showing value and growth opportunities',
      'Product roadmap alignment with customer needs',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpPeople', 'icpOrganization', 'icpMetadata'],
      },
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'expansion-discovery',
    goal: 'Expansion Opportunity',
    title: 'Expansion Discovery',
    prompt:
      'How do you identify specific expansion opportunities within the account?',
    examplePrompts: [
      'Document use cases for additional departments or teams',
      'Map current limitations impact on business goals',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'solution-validation',
    goal: 'Solution Validation',
    title: 'Value Demonstration',
    prompt: 'How do you validate the expanded solution with stakeholders?',
    examplePrompts: [
      'ROI analysis for upgraded features or increased capacity',
      'Pilot program with new department or team',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'expansion-proposal',
    goal: 'Proposal & Approval',
    title: 'Expansion Proposal',
    prompt: 'What needs to be included in the expansion proposal?',
    examplePrompts: [
      'Updated pricing and terms for expanded usage',
      'Implementation plan for new features or users',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'expansion-closed',
    goal: 'Expansion Confirmed',
    title: 'Expansion Closed',
    prompt: 'What signals that the expansion is confirmed?',
    examplePrompts: [
      'Contract amendment signed and processed',
      'New licenses activated or limits increased',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
]

// Partnership Pipeline Steps
const partnershipSteps: Step[] = [
  {
    id: 'partner-outreach',
    goal: 'Initial Engagement',
    title: 'Partnership Outreach',
    prompt: 'How do you initiate partnership discussions?',
    examplePrompts: [
      'Executive-level introduction through mutual connections',
      'Direct outreach to partnership team with value proposition',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata', 'icpPeople'],
      },
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'partnership-discovery',
    goal: 'Partnership Scope',
    title: 'Partnership Discovery',
    prompt: 'How do you define the potential partnership scope and value?',
    examplePrompts: [
      'Joint workshop to identify integration opportunities',
      'Market analysis of combined solution value',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'partnership-validation',
    goal: 'Technical & Business Validation',
    title: 'Partnership Validation',
    prompt: 'How do you validate the partnership feasibility?',
    examplePrompts: [
      'Technical proof of concept for integration',
      'Joint customer feedback sessions',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'partnership-terms',
    goal: 'Partnership Agreement',
    title: 'Partnership Terms',
    prompt: 'What key terms need to be agreed upon?',
    examplePrompts: [
      'Revenue sharing model and go-to-market strategy',
      'Technical integration roadmap and resource commitments',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'partnership-launch',
    goal: 'Partnership Launch',
    title: 'Partnership Launch',
    prompt: 'What defines a successfully launched partnership?',
    examplePrompts: [
      'Partnership agreement signed and implementation plan approved',
      'Joint marketing materials and sales enablement ready',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
]

const vcInvestmentSteps: Step[] = [
  {
    id: 'deal-sourcing',
    goal: 'Building Deal Flow',
    title: 'Deal Sourcing',
    prompt:
      'What makes a startup company a fit for your fund? Stage, sector, metrics, location, etc.',
    examplePrompts: [
      'We invest in B2B SaaS companies at Series A with >$1M ARR',
      'We focus on fintech startups in Europe at seed stage',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata'],
      },
    ],
  },
  {
    id: 'stakeholder-mapping',
    goal: 'Key Decision Makers',
    title: 'Target Stakeholders',
    prompt:
      'Which roles/people do you need to engage with at target companies?',
    examplePrompts: [
      'We primarily work with CEOs and CFOs of startups',
      'Need to connect with founders and key technical leadership',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpPeople'],
      },
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'initial-review',
    goal: 'Initial Assessment',
    title: 'Initial Review',
    prompt: 'What needs to happen in first meetings with founders?',
    examplePrompts: [
      'Initial pitch deck review and founder background check',
      'Preliminary market size and competitive analysis',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'due-diligence',
    goal: 'Deep Dive',
    title: 'Due Diligence',
    prompt: 'What do you evaluate during due diligence?',
    examplePrompts: [
      'Complete technical assessment and customer references',
      'Detailed financial model review and market analysis',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'investment-committee',
    goal: 'Investment Decision',
    title: 'Investment Committee',
    prompt: 'What needs to happen for investment approval?',
    examplePrompts: [
      'Present full investment memo to partnership',
      'Get formal IC vote and finalize deal terms',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'closing',
    goal: 'Deal Execution',
    title: 'Closing',
    prompt: 'What defines a successfully closed investment?',
    examplePrompts: [
      'All documents signed and wire transfer completed',
      'Portfolio onboarding process initiated',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
]

const otherSteps: Step[] = [
  {
    id: 'other',
    sufficient: true,
    goal: 'Other',
    title: 'Other',
    prompt: '',
    examplePrompts: [],
    effectOnPipeline: [],
  },
]

const stepsMap = {
  [NativeSuggestedPipelineTypes.NEW_CUSTOMER.key]: salesSteps,
  [NativeSuggestedPipelineTypes.OTHER.key]: otherSteps,
  [NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key]: fundraisingSteps,
  [NativeSuggestedPipelineTypes.PARTNER.key]: partnershipSteps,
  [NativeSuggestedPipelineTypes.EXISTING_CUSTOMER.key]: upsellSteps,
}

const PipelineCreateInteractive = ({ id, workspaceId, refetch }) => {
  const { pipelineType } = useParams()
  const { currentUser } = useAuth()
  const [title, setTitle] = useState('')
  const [selectedPipelineType, setSelectedPipelineType] = useState(null)
  const [stepState, setStepState] = useState<Step[]>([])

  const {
    data,
    loading,
    refetch: refetchPipelineForSetup,
  } = useQuery(GET_PIPELINE_INTERACTIVE, {
    variables: {
      id,
      workspaceId,
    },
    skip: !id || !workspaceId,
    onCompleted: (data) => {
      logger.dev({ data })
      if (data?.pipeline?.setupSteps?.length > 0) {
        //setStepState(data.pipeline.setupSteps)
      }
    },
  })

  const refetchAll = useCallback(() => {
    refetchPipelineForSetup()
    refetch()
  }, [refetchPipelineForSetup, refetch])

  const steps = useMemo(() => {
    return selectedPipelineType
      ? stepsMap[selectedPipelineType]
      : stepsMap[data?.pipeline?.type || pipelineType]
  }, [selectedPipelineType])

  logger.dev({ steps })

  const [createPipeline, { data: createData, loading: createLoading }] =
    useMutation(CREATE_NEW_PIPELINE_INTERACTIVE, {
      onCompleted: (data) => {
        navigate(
          routes.objectDetail({
            objectId: data.createPipeline.id,
            objectTypeSlug: 'pipelines',
            workspaceId,
          })
        )
      },
    })

  const [updatePipeline, { data: updateData, loading: updateLoading }] =
    useMutation(UPDATE_PIPELINE_INTERACTIVE, {
      onCompleted: (data) => {
        logger.dev({ data })
      },
    })

  const [setupPipeline, { data: setupData, loading: setupLoading }] =
    useMutation(SETUP_PIPELINE_INTERACTIVE, {
      onCompleted: (data) => {
        const notes = data.setupPipeline
        const newSteps = stepState.map((s) => {
          const note = notes.find((n) => n.key === s.id)
          return {
            ...s,
            notesToUser: note ? note?.missingInformation : s.notesToUser,
            sufficient: note ? note?.confidence > 69 : s.sufficient,
            confidence: note ? note?.confidence : s.confidence,
          }
        })
        setStepState(newSteps)
        handleUpdatePipeline(newSteps)
      },
    })

  const pipeline = useMemo(() => {
    return data?.pipeline || createData?.createPipeline
      ? mergeObjects(data?.pipeline, createData?.createPipeline)
      : null
  }, [data, createData])

  const handleCreatePipeline = () => {
    const type = selectedPipelineType
    if (!type) {
      toast.error('No pipeline type selected')
      return
    }

    toast.promise(
      createPipeline({
        variables: {
          input: {
            workspaceId,
            title,
            type,
          },
        },
      }),
      {
        loading: 'Creating pipeline...',
        success: 'Pipeline created!',
        error: 'Error creating pipeline',
      }
    )
  }

  const handleUpdatePipeline = useCallback(
    (steps: Step[]) => {
      if (!pipeline) {
        return
      }
      const input = {
        id: pipeline.id,
        workspaceId,
        setupSteps: steps,
        title: pipeline.title,
      }

      updatePipeline({
        variables: {
          id: pipeline.id,
          input,
        },
      })
      refetchAll()
    },
    [pipeline, updatePipeline, workspaceId]
  )

  const handleSubmit = (stepId) => {
    logger.dev({ stepState })
    const step = stepState.find((s) => s.id === stepId)
    step.changed = true
    const canonicalStep = steps.find((s) => s.id === stepId)
    step.effectOnPipeline = canonicalStep?.effectOnPipeline
    for (const effect of step.effectOnPipeline || []) {
      if (effect.stageType) {
        effect.outputFields.push('description')
      }
    }
    toast.promise(
      setupPipeline({
        variables: {
          id: pipeline.id,
          workspaceId,
          setupSteps: [step],
        },
      }),
      {
        loading: 'Taking a look...',
        success: ({ data }) => {
          logger.dev({ modelResultData: data })
          const resultStep = data.setupPipeline?.[0]
          const confidence = resultStep?.confidence
          logger.dev({ resultStep, confidence })

          let message: string
          if (confidence > 69) {
            message = 'Looks good, way to go!'
          } else if (confidence > 40) {
            message = 'Getting closer! Keep adding details ...'
          } else {
            message = 'Could use more information - see our feedback below.'
          }
          refetch()
          return message
        },
        error: 'Error',
      }
    )
  }

  useEffect(() => {
    if (!pipeline || !id) {
      logger.dev({ cannotInitialize: true, selectedPipelineType, id, steps })
      return
    }

    if (!selectedPipelineType && pipeline.type) {
      setSelectedPipelineType(pipeline.type)
    }

    if (title === '' && pipeline.title) {
      setTitle(pipeline.title)
    }

    if (steps && stepState.length === 0) {
      logger.dev({ steps })
      if (pipeline && !pipeline.setupSteps && steps?.length > 0) {
        const initialSteps = steps.map((step) => ({
          ...step,
          userResponse: '',
          sufficient: step.id === 'other',
          examplesOpen: false,
          changed: false,
        }))
        setStepState(initialSteps)
        handleUpdatePipeline(initialSteps)
        return
      }

      if (steps.length === 1 && steps[0].id === 'other') {
        const initOtherStep = [
          {
            ...steps[0],
            sufficient: true,
          },
        ]
        setStepState(initOtherStep)
        handleUpdatePipeline(initOtherStep)
        return
      } else if (!(pipeline?.setupSteps?.length > 0) && steps?.length > 0) {
        // Initialize
        setStepState(
          steps?.map((step) => ({
            ...step,
            userResponse: '',
            sufficient: step.id === 'other',
            examplesOpen: false,
            changed: false,
          }))
        )
      } else if (pipeline?.setupSteps?.length > 0) {
        // Load from db saved pipeline-setup state
        setStepState(pipeline.setupSteps.map((s) => ({ ...s, changed: false })))
      }
    }
  }, [
    stepState.length,
    id,
    pipeline,
    selectedPipelineType,
    title,
    steps,
    handleUpdatePipeline,
  ])

  const handleAddTestResponses = () => {
    setStepState((prev) => {
      const newSteps = prev.map((s) => ({
        ...s,
        sufficient: true,
        userResponse: testResponses.find((t) => t.id === s.id)?.response,
      }))
      handleUpdatePipeline(newSteps)
      return newSteps
    })
  }

  const farthestStepReached = useMemo(() => {
    if (!stepState) {
      return 0
    }
    const farthestStep = stepState.reduce((acc, step) => {
      return step.sufficient ? step.id : acc
    }, '')
    const index = stepState.findIndex((s) => s.id === farthestStep)
    return index || 0
  }, [stepState])

  const handleCompletePipelineSetup = () => {
    //alert('hooray')
  }

  // Add effect to handle pipelineType param
  useEffect(() => {
    if (pipelineType && !selectedPipelineType) {
      setSelectedPipelineType(pipelineType)
      // Set default title based on pipeline type
      const pipelineTypeInfo = NativeSuggestedPipelineTypes[pipelineType]
      if (pipelineTypeInfo) {
        setTitle(pipelineTypeInfo.label)
      } else {
        logger.warn(`Invalid pipeline type provided: ${pipelineType}`)
      }
    }
  }, [pipelineType, selectedPipelineType])

  return !pipeline && !loading ? (
    <Box
      sx={{
        width: '100%',
        height: 'calc(70vh)',
        background: (theme) => theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!selectedPipelineType && (
        <Card sx={{ width: '624px' }}>
          <CardContent>
            <Row
              gap={2}
              sx={{ mt: 3, flexWrap: 'wrap' }}
            >
              {Object.entries(NativeSuggestedPipelineTypes)
                .filter(([key, value]) => value.showInChooser)
                .map(([key, value]) => (
                  <Box
                    key={key}
                    sx={{
                      width: key === 'OTHER' ? '100%' : '280px',
                      height: key === 'OTHER' ? '72px' : '128px',
                      flexShrink: 0,
                      border: (theme) =>
                        key === 'OTHER'
                          ? 'none'
                          : `2px solid ${theme.palette.divider}`,
                      borderRadius: 1,
                      px: 4,
                      py: key === 'OTHER' ? 2 : 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedPipelineType(key)}
                  >
                    <Row
                      gap={2}
                      sx={{ alignItems: 'top' }}
                    >
                      <Box
                        sx={{
                          display: key === 'OTHER' ? 'none' : 'block',
                        }}
                      >
                        {React.cloneElement(value.icon, {
                          size: 40,
                        })}
                      </Box>
                      <Box>
                        <Typography variant="h3">{value.label}</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {value.informalLabel}
                        </Typography>
                      </Box>
                    </Row>
                  </Box>
                ))}
            </Row>
          </CardContent>
        </Card>
      )}
      {selectedPipelineType && (
        <Card sx={{ width: '624px' }}>
          <CardContent>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth={true}
              placeholder="Enter a name for your pipeline"
              onKeyDown={(e) => {
                logger.dev({ key: e.key })
                if (e.key === 'Enter') {
                  handleCreatePipeline()
                }
              }}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between', px: 0 }}>
            {!pipelineType && (
              <Button
                startIcon={<IconChevronLeft size={14} />}
                onClick={() => setSelectedPipelineType(null)}
                disabled={createLoading}
              >
                Back
              </Button>
            )}
            <Button
              variant={!title ? 'outlined' : 'contained'}
              color={!title ? 'primary' : 'secondary'}
              disableElevation={true}
              disabled={!title || createLoading}
              onClick={handleCreatePipeline}
              fullWidth={!!pipelineType}
            >
              Create pipeline
            </Button>
          </CardActions>
        </Card>
      )}
    </Box>
  ) : (
    pipeline && stepState && (
      <>
        <Box
          sx={{
            height: 'calc(100% - 64px)',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              mt: 3,
            }}
          >
            {stepState?.map((step, index) => (
              <Box
                key={step.id}
                sx={{
                  width: '620px',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: 2,
                  padding: 2,
                  background: (theme) => theme.palette.background.paper,
                }}
              >
                <Row sx={{ justifyContent: 'space-between' }}>
                  <Typography variant="h3">{step.goal}</Typography>
                  <Row gap={1}>
                    {step.sufficient ? (
                      <>
                        <Button
                          variant="text"
                          color="primary"
                          size="small"
                          disableElevation={true}
                          onClick={() =>
                            setStepState((prev) =>
                              prev.map((s) =>
                                s.id === step.id
                                  ? { ...s, sufficient: false }
                                  : s
                              )
                            )
                          }
                        >
                          edit
                        </Button>
                        <IconCheck
                          style={{
                            color: step.sufficient ? 'white' : 'gray',
                            opacity: step.sufficient ? 1 : 0.5,
                            backgroundColor: step.sufficient
                              ? '#5FC18F'
                              : 'transparent',
                            borderRadius: '50%',
                            padding: 2,
                          }}
                        />
                      </>
                    ) : (
                      <Chip
                        label={step.title}
                        size="small"
                        variant="outlined"
                        sx={{
                          color: (theme) => theme.palette.text.primary,
                          width: '160px',
                          opacity:
                            index === farthestStepReached + 1 ||
                            (farthestStepReached === stepState.length - 1 &&
                              !step.sufficient)
                              ? 1.0
                              : 0.6,
                        }}
                      />
                    )}
                  </Row>
                </Row>
                {index === farthestStepReached + 1 ||
                (farthestStepReached === stepState.length - 1 &&
                  !step.sufficient) ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        background: (theme) =>
                          lighten(theme.palette.divider, 0.2),
                        p: 1,
                        borderRadius: '4px',
                        mb: '-1px',
                        fontSize: '12px',
                        letterSpacing: '-0.3px',
                        fontWeight: 600,
                        mt: 2,
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderBottom: 'none',
                      }}
                    >
                      {step.prompt}
                      <Chip
                        size="small"
                        variant="outlined"
                        sx={{ flexShrink: 0, height: '16px', ml: 1 }}
                        onClick={() =>
                          setStepState((prev) =>
                            prev.map((s) =>
                              s.id === step.id
                                ? { ...s, examplesOpen: !s.examplesOpen }
                                : s
                            )
                          )
                        }
                        label={
                          step.examplesOpen ? 'Hide examples' : 'See examples'
                        }
                      />
                    </Typography>

                    {step.examplesOpen && (
                      <Box
                        sx={{
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderTop: 'none',
                          borderBottom: 'none',
                          p: 2,
                        }}
                      >
                        {step.examplePrompts.map((prompt, promptIndex) => (
                          <Typography
                            key={`${promptIndex}-${index}`}
                            variant="body2"
                            sx={{
                              fontStyle: 'italic',
                              color: (theme) => theme.palette.text.secondary,
                              my: 1,
                            }}
                          >
                            {`"${prompt}"`}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {step.notesToUser && (
                      <Alert
                        severity={
                          step.confidence > 69
                            ? 'success'
                            : step.confidence > 40
                              ? 'info'
                              : 'warning'
                        }
                        sx={{
                          color: 'text.secondary',
                          lineHeight: '150%',
                          fontSize: '12px',
                          borderRadius: '0px',
                          my: 0,
                          px: 1,
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderTop: 'none',
                          borderBottom: 'none',
                          '& .MuiAlert-icon': {
                            mr: 1,
                          },
                        }}
                        icon={
                          step.confidence > 69 ? (
                            <IconThumbUpFilled size={18} />
                          ) : step.confidence > 40 ? (
                            <IconBrain size={18} />
                          ) : (
                            <IconAlertSquareFilled size={18} />
                          )
                        }
                      >
                        <AlertTitle>
                          {step.confidence > 69
                            ? 'Great answer. We have notes below if you want it to be even better.'
                            : step.confidence > 40
                              ? `Good start, let's improve it a bit`
                              : `Let's take a closer look`}
                        </AlertTitle>
                        {step.notesToUser}
                      </Alert>
                    )}
                    <TextField
                      value={step.userResponse}
                      fullWidth={true}
                      multiline={true}
                      rows={6}
                      onChange={(e) =>
                        setStepState((prev) =>
                          prev.map((s) =>
                            s.id === step.id
                              ? { ...s, userResponse: e.target.value }
                              : s
                          )
                        )
                      }
                      sx={{
                        mb: 2,
                        '& .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline':
                          {
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderColor: (theme) => theme.palette.divider,
                          },
                      }}
                    />
                    <Row
                      gap={2}
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant={
                          step.confidence > 69
                            ? 'text'
                            : step.notesToUser
                              ? 'outlined'
                              : 'contained'
                        }
                        disabled={!(step.userResponse?.length > 10)}
                        color={step.confidence > 69 ? 'primary' : 'secondary'}
                        disableElevation={true}
                        onClick={() => handleSubmit(step.id)}
                        fullWidth={true}
                      >
                        {step.notesToUser ? 'Try again' : 'Tell Day.ai'}
                      </Button>
                      {step.confidence > 69 && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          disableElevation={true}
                          onClick={() => {
                            setStepState((prev) =>
                              prev.map((s) =>
                                s.id === step.id
                                  ? { ...s, sufficient: true }
                                  : s
                              )
                            )
                          }}
                          fullWidth={true}
                        >
                          Next
                        </Button>
                      )}
                    </Row>
                  </>
                ) : null}
              </Box>
            ))}
            {false &&
              stepState &&
              farthestStepReached === stepState.length - 1 && (
                <Button
                  disabled={farthestStepReached < stepState?.length - 1}
                  onClick={handleCompletePipelineSetup}
                  variant="contained"
                  color="secondary"
                  disableElevation={true}
                  sx={{
                    flexShrink: 0,
                    opacity:
                      farthestStepReached !== stepState.length - 1 ? 0 : 1,
                    transition: 'opacity 0.5s ease-in-out',
                    width: '620px',
                    height:
                      farthestStepReached !== stepState.length - 1
                        ? '0px'
                        : '64px',
                  }}
                >
                  You're ready, let's go!
                </Button>
              )}
          </Box>
        </Box>
        {stepState && farthestStepReached !== stepState.length - 1 && (
          <Row
            sx={{
              opacity: 1, // farthestStepReached !== stepState.length - 1 ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
              width: '100%',
              height: '64px',
              px: 3,
              background: (theme) => theme.palette.background.paper,
              boxShadow: `
            0 1px 2px rgba(136, 161, 183, 0.07),
            0 2px 4px rgba(136, 161, 183, 0.07),
            0 4px 8px rgba(136, 161, 183, 0.07),
            0 8px 16px rgba(136, 161, 183, 0.07),
            0 16px 32px rgba(136, 161, 183, 0.07),
            0 32px 64px rgba(136, 161, 183, 0.07)
          `,
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              justifyContent: 'space-between',
            }}
            gap={4}
          >
            <Typography
              variant="h2"
              sx={{ flexShrink: 0 }}
            >{`${pipeline.title}`}</Typography>
            <Box sx={{ my: 3, width: '100%', flexShrink: 1 }}>
              {stepState?.length && (
                <LinearProgress
                  value={((farthestStepReached + 1) / stepState?.length) * 100}
                  variant="determinate"
                  color="secondary"
                  sx={{ height: 24, borderRadius: 20 }}
                />
              )}
            </Box>
            <Row gap={1}>
              {isInternalUser(currentUser) && (
                <IconButton onClick={handleAddTestResponses}>
                  <IconPlus size={14} />
                </IconButton>
              )}
            </Row>
          </Row>
        )}
      </>
    )
  )
}

/* what do we need to know?

Organization profile (firmographics and also things we could research)
Roles you sell to (target seniority, department)
What is discovery like? What needs to happen for discovery to be sufficient/complete? What steps do you take to gain a the necessary understanding of your prospect's problems to be able to present a viable, specific solution for them to evaluate?
What steps do buyers take to evaluate your product before they purchase? How do you know they are in those stages? Do you have a free trial or similar? Do you offer multiple demos? Do you deal with procurement?
How does a buyer complete their purchase? Do you send a proposal, a payment link, do you offer multiple quotes or options? (Becomes entrance criteria for consideration/negotiation and closed-won)
*/

export default PipelineCreateInteractive
