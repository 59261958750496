import { Chip } from '@mui/material'

import { getObjectByKeyWithFallback } from 'src/lib/safeUi'

const types = {
  SUPPORT: {
    label: 'Support',
    color: 'warning',
  },
  FOLLOWUP: {
    label: 'Follow-up',
    color: 'secondary',
  },
  MEETINGPREP: {
    label: 'Prep',
    color: 'info',
  },
  FEATURE_REQUEST: {
    label: 'Feature',
    color: 'success',
  },
  OTHER: {
    label: 'General',
    color: 'secondary',
  },
}

const ActionTypeChip = ({ type }: { type: string }) => {
  const { label, color } = getObjectByKeyWithFallback(types, type, 'OTHER')
  return (
    <Chip
      size="small"
      label={label}
      variant="outlined"
      sx={{
        width: '100%',
        fontSize: '11px',
        fontWeight: 600,
        letterSpacing: '-0.2px',
        borderColor: (theme) => theme.palette[color].light,
        color: (theme) => theme.palette[color].main,
      }}
    />
  )
}

export default ActionTypeChip
