import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { IconListTree, IconTable } from '@tabler/icons-react'

import { routes, navigate, useRouteName } from '@redwoodjs/router'

import { logger } from 'src/lib/logger'

const selectedStyle = {
  background: (theme) => theme.palette.secondary.main,
  color: (theme) => theme.palette.secondary.contrastText,
  '&:hover': {
    background: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.secondary.contrastText,
  },
}
const style = {
  width: '48px',
  height: '30px',
  border: 'none !important',
  borderRadius: '3px !important',
  //background: (theme) => lighten(theme.palette.action.selected, 0.3),
}

const PagesNav = () => {
  const route = useRouteName()
  logger.info(route)
  return (
    <ToggleButtonGroup
      value={route}
      exclusive
      size="small"
      sx={{
        borderRadius: '3px',
        height: '40px',
        p: '4px',
        border: '1px solid',
        borderColor: (theme) => theme.palette.divider,
        gap: '4px',
        background: (theme) => theme.palette.background.paper,
      }}
      onChange={(e, value) => {
        navigate(routes[value]())
      }}
    >
      <ToggleButton
        value="pages"
        sx={style}
      >
        <IconTable
          size={18}
          stroke={2}
        />
      </ToggleButton>
      <ToggleButton
        value="pagesDirectory"
        sx={style}
      >
        <IconListTree
          size={18}
          stroke={2}
        />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default PagesNav
