import { useCallback, useContext, useEffect, useState } from 'react'

import {
  Box,
  darken,
  GlobalStyles,
  lighten,
  List,
  ListItemButton,
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import {
  IconBolt,
  IconBubbleText,
  IconBuildingSkyscraper,
  IconBuildingStore,
  IconCalendar,
  IconChartBar,
  IconColumns2,
  IconFiles,
  IconHeartHandshake,
  IconLaurelWreath,
  IconMicrophone,
  IconReportAnalytics,
  IconSearch,
  IconServer,
  IconSitemap,
  IconSmartHome,
  IconTargetArrow,
  IconUser,
} from '@tabler/icons-react'
import ReactGA from 'react-ga4'
import { Toaster } from 'react-hot-toast'
import { v4 as uuid } from 'uuid'

import { routes, Link, navigate } from '@redwoodjs/router'
import { useLocation } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router'

import ObjectFinder from 'src/components/ObjectFinder/ObjectFinder'
import Row from 'src/components/Row/Row'
import Sidebar from 'src/components/Sidebar/Sidebar'
import UserErrorRemediation from 'src/components/UserErrorRemediation/UserErrorRemediation'
import WorkspaceInviteBanner from 'src/components/WorkspaceInviteBanner/WorkspaceInviteBanner'
import ProfileController from 'src/layouts/ModernLayout/ProfileController/ProfileController'
import WorkspaceDropdown from 'src/layouts/ModernLayout/WorkspaceDropdown/WorkspaceDropdown'
import { AnalyticsActions, AnalyticsCategories } from 'src/lib/analytics'
import { DayContext, DayProvider } from 'src/lib/dayContext'
import {
  ungatedForCrm3,
  ungatedForCustomers,
  ungatedForHome,
  ungatedForMarketing,
  ungatedForProduct,
} from 'src/lib/gates'
import { logger } from 'src/lib/logger'

import PageTree from '../../components/Page/PageTree/PageTree'
import { useAuth } from 'src/auth'
import { AvatarCacheProvider } from 'src/components/AvatarCacheProvider/AvatarCacheProvider'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
  nonce: uuid(),
})

type ModernLayoutProps = {
  children?: React.ReactNode
}

export const headerStyle = {
  fontWeight: 600,
  fontSize: '0.8rem',
  ml: '20px',
  mt: 2,
  cursor: 'pointer',
}

export const DrawerNavLabel = ({ label, collapsed }) => (
  <Box
    component={'span'}
    sx={{
      opacity: collapsed ? 0 : 1,
      transition: 'all 0.22s ease',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.4rem',
      fontSize: '0.9rem',
      width: collapsed ? '0px' : 'auto',
      ml: '8px',
      color: (theme) =>
        collapsed ? theme.palette.divider : theme.palette.text.primary,
    }}
  >
    {label}
  </Box>
)

const sectionsByRoute = {
  ['/today']: 'TODAY',
  ['/prospects']: 'SALES',
  ['/opportunities']: 'SALES',
  ['/reports/pipeline']: 'SALES',
  ['/marketing']: 'MARKETING',
  ['/customers']: 'CUSTOMERS',
  ['/recordings']: 'DATA',
  ['/actions']: 'DATA',
  ['/organizations']: 'DATA',
  ['/pages']: 'DATA',
  ['/people']: 'DATA',
  ['/pipelines']: 'SALES',
  ['/context']: 'DATA',
  ['/product']: 'PRODUCT',
}

const DrawerNavSubItem = ({ label, collapsed, route, currentRoute, icon }) => {
  const sectionSelected =
    sectionsByRoute[route] === sectionsByRoute[`/${currentRoute}`]
  const routeSelected = route === `/${currentRoute}`
  const show = sectionSelected && !collapsed

  return (
    <ListItemButton
      component={'span'}
      onClick={() => {
        navigate(route)
      }}
      sx={{
        opacity: show ? 1 : 0,
        transition: 'all 0.12s ease',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: show ? '26px' : '0px',
        fontWeight: 600,
        fontSize: '12px',
        letterSpacing: '-0.36px',
        py: show ? '4px' : 0,
        mt: show ? 1 : 0,
        px: '0px',
        borderRadius: '4px',
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) =>
          routeSelected ? theme.palette.secondary.light : 'transparent',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.secondary.light,
          color: (theme) => theme.palette.secondary.dark,
        },
      }}
    >
      {icon &&
        React.cloneElement(icon, {
          size: 20,
          style: {
            flexShrink: 0,
            marginRight: '11px',
            marginLeft: '10px',
          },
        })}
      {label}
    </ListItemButton>
  )
}

const drawerWidth = 280
const drawerWidthCollapsed = 64
const drawerMargin = '20px'
const listItemHeight = '40px'

const NavItem = ({
  icon,
  label,
  route,
  selected,
  collapsed,
  hasSubItems = false,
}) => {
  const adjustForSubItems = hasSubItems && !collapsed && selected
  return (
    <ListItemButton
      className="navItem"
      disableGutters={true}
      selected={selected}
      LinkComponent={Link}
      href={route}
      onClick={() => {
        navigate(route)
      }}
      sx={{
        mt: adjustForSubItems ? 0 : 1,
        fontWeight: 500,
        borderRadius: '4px',
        width: collapsed ? '64px' : 'auto',
        minWidth: collapsed ? '36px' : 'auto',
        p: '8px',
        ml: adjustForSubItems ? '0px' : '4px',
        height: listItemHeight,
        transition: 'all 0.22s ease',
        '&:hover': {
          background: (theme) => theme.palette.secondary.light,
        },
        '&.Mui-selected': {
          background: (theme) => theme.palette.secondary.light,
          '&:hover': {
            background: (theme) => darken(theme.palette.secondary.light, 0.2),
          },
        },
        '& .navIcon': {
          flexShrink: 0,
          height: '24px',
          width: '24px',
        },
      }}
    >
      {React.cloneElement(icon, { className: 'navIcon' })}
      <DrawerNavLabel
        label={label}
        collapsed={collapsed}
      />
    </ListItemButton>
  )
}

const ModernLayoutInner = ({ children }: ModernLayoutProps) => {
  const [collapsed, setCollapsed] = useState(true)
  const route = useRouteName()

  const {
    userCoreContact,
    sidebarObject,
    setSidebarObject,
    selectedWorkspace,
    workAccounts,
    workspaces,
  } = useContext(DayContext)

  const memoizedSetSidebarObject = useCallback(setSidebarObject, [
    setSidebarObject,
  ])

  useEffect(() => {
    const handleMouseLeaveWindow = (event) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('mouseout', handleMouseLeaveWindow)

    return () => {
      document.removeEventListener('mouseout', handleMouseLeaveWindow)
    }
  }, [])

  useEffect(() => {
    const label = sidebarObject?.objectType || 'Unknown objectType'
    const category = AnalyticsCategories.SIDEBAR
    const action = AnalyticsActions.OPEN
    const event = {
      category,
      action,
      label,
    }

    if (sidebarObject) {
      ReactGA.event(event)
    }

    const handleMouseMove = (e) => {
      const sidebar = document.getElementById('sidebar')
      const workspaceDropdown = document.getElementById('workspace-select-menu')
      if (
        sidebar &&
        !sidebar.contains(e.target) &&
        !workspaceDropdown?.contains(e.target)
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [sidebarObject, setCollapsed])

  const workspaceInvites = workspaces?.filter(
    (workspace) => workspace.status === 'INVITED'
  )

  const handleSearch = useCallback(
    (searchItem) => {
      if (searchItem.objectType === 'page') {
        navigate(routes.pageDetail({ id: searchItem.objectId }))
      } else {
        setSidebarObject(searchItem)
      }
    },
    [setSidebarObject]
  )

  return (
    userCoreContact && (
      <>
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: (theme) =>
                `${theme.palette.background.paper} !important`,
            },
          }}
        />
        <Toaster />
        <CssBaseline />

        <Sidebar
          key={sidebarObject?.objectId || 'empty'}
          crmObject={sidebarObject}
          onClose={() => {}}
        />
        <Box
          sx={{
            color: (theme) => theme.palette.text.primary,
            background: (theme) => theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'row',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <Box
            id="sidebar"
            sx={{
              color: (theme) => theme.palette.text.primary,
              background: (theme) =>
                collapsed
                  ? theme.palette.background.paper
                  : theme.palette.background.default,
              borderRight: (theme) =>
                collapsed ? `1px solid ${theme.palette.divider}` : 'none',
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '100vh',
              width: collapsed ? drawerWidthCollapsed : drawerWidth,
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 3,
              transition: 'all 0.22s ease',
              boxShadow: collapsed
                ? 'none'
                : `0 1px 1px rgba(0,0,0,0.12),
              0 2px 2px rgba(0,0,0,0.12),
              0 4px 4px rgba(0,0,0,0.12),
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12)`,
            }}
            component="div"
            onMouseOver={() => {
              setCollapsed(false)
            }}
            onMouseOut={() => {
              setCollapsed(true)
            }}
          >
            <WorkspaceDropdown collapsed={collapsed} />
            <Box sx={{ mt: 1 }}>
              {collapsed ? (
                <Row
                  sx={{
                    justifyContent: 'center',
                    height: listItemHeight,
                    mt: 1,
                    opacity: collapsed ? 1 : 0,
                    transition: 'all 0.4s ease',
                  }}
                >
                  <Box>
                    <IconSearch
                      size={20}
                      stroke={2.4}
                    />
                  </Box>
                </Row>
              ) : (
                <Box sx={{ mt: 1, pt: '4px', minHeight: listItemHeight }}>
                  <ObjectFinder
                    onSelect={memoizedSetSidebarObject}
                    placeholder="Search Day.ai"
                    textFieldSx={{
                      px: '20px',
                      mx: 0,
                    }}
                    inputSx={{
                      fontWeight: 500,
                      fontSize: '0.9rem',
                    }}
                  />
                </Box>
              )}
            </Box>
            <List
              disablePadding={true}
              sx={{
                mt: 0,
                px: 1,
                width: collapsed ? drawerWidthCollapsed : drawerWidth,
              }}
              id="sidebar-nav-item-list"
            >
              {ungatedForHome(userCoreContact) && (
                <NavItem
                  icon={<IconSmartHome />}
                  label="Home"
                  route={routes.home()}
                  collapsed={collapsed}
                  selected={
                    window.location.pathname === routes.home() && !collapsed
                  }
                />
              )}
              <NavItem
                icon={<IconCalendar />}
                label="Today"
                route={routes.today()}
                collapsed={collapsed}
                selected={
                  window.location.pathname === routes.today() && !collapsed
                }
              />
              {ungatedForCrm3({ selectedWorkspace, workAccounts }) && (
                <Box
                  sx={
                    !collapsed &&
                    sectionsByRoute[`/${route}`] ===
                      sectionsByRoute[routes.opportunities()]
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            sectionsByRoute[`/${route}`] ===
                            sectionsByRoute[routes.opportunities()]
                              ? lighten(theme.palette.secondary.light, 0.6)
                              : 'transparent',
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconChartBar />}
                    label="Sales"
                    route={routes.opportunities()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      window.location.pathname === routes.opportunities() &&
                      !collapsed
                    }
                  />

                  <DrawerNavSubItem
                    label="Opportunities"
                    collapsed={collapsed}
                    route={routes.opportunities()}
                    currentRoute={route}
                    icon={<IconTargetArrow />}
                  />
                  <DrawerNavSubItem
                    label="Pipelines"
                    collapsed={collapsed}
                    route={routes.pipelines()}
                    currentRoute={route}
                    icon={<IconColumns2 />}
                  />
                  {/*}
                  <DrawerNavSubItem
                    label="Prospects"
                    collapsed={collapsed}
                    route={routes.prospects()}
                    currentRoute={route}
                    icon={<IconBinoculars />}
                  />*/}
                  <DrawerNavSubItem
                    label="Reports"
                    collapsed={collapsed}
                    route={routes.reportsPipeline()}
                    currentRoute={route}
                    icon={<IconChartBar />}
                  />
                </Box>
              )}
              {ungatedForMarketing(userCoreContact) && (
                <Box
                  sx={
                    !collapsed &&
                    sectionsByRoute[`/${route}`] ===
                      sectionsByRoute[routes.marketing()]
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            sectionsByRoute[`/${route}`] ===
                            sectionsByRoute[routes.marketing()]
                              ? lighten(theme.palette.secondary.light, 0.6)
                              : 'transparent',
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconReportAnalytics />}
                    label="Marketing"
                    route={routes.marketing()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      window.location.pathname === routes.marketing() &&
                      !collapsed
                    }
                  />

                  <DrawerNavSubItem
                    label="Segments"
                    collapsed={collapsed}
                    route={routes.marketing()}
                    currentRoute={route}
                    icon={<IconSitemap />}
                  />
                </Box>
              )}
              {ungatedForCustomers(userCoreContact) && (
                <NavItem
                  icon={<IconHeartHandshake />}
                  label="Customers"
                  route={routes.customers()}
                  collapsed={collapsed}
                  selected={
                    window.location.pathname === routes.customers() &&
                    !collapsed
                  }
                />
              )}
              {ungatedForProduct(userCoreContact) && (
                <Box
                  sx={
                    !collapsed &&
                    sectionsByRoute[`/${route}`] ===
                      sectionsByRoute[routes.product()]
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            sectionsByRoute[`/${route}`] ===
                            sectionsByRoute[routes.product()]
                              ? lighten(theme.palette.secondary.light, 0.6)
                              : 'transparent',
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconBuildingStore />}
                    label="Product"
                    route={routes.product()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      window.location.pathname === routes.product() &&
                      !collapsed
                    }
                  />

                  <DrawerNavSubItem
                    label="Feature Requests"
                    collapsed={collapsed}
                    route={routes.product()}
                    currentRoute={route}
                    icon={<IconLaurelWreath />}
                  />
                </Box>
              )}
              <Box
                sx={
                  !collapsed &&
                  sectionsByRoute[`/${route}`] ===
                    sectionsByRoute[routes.recordings()]
                    ? {
                        p: '4px',
                        borderRadius: '8px',
                        background: (theme) =>
                          sectionsByRoute[`/${route}`] ===
                          sectionsByRoute[routes.recordings()]
                            ? lighten(theme.palette.secondary.light, 0.6)
                            : 'transparent',
                        mt: 1,
                        transition: 'all 0.22s ease',
                      }
                    : {
                        transition: 'all 0.22s ease',
                      }
                }
              >
                <NavItem
                  icon={<IconServer />}
                  label="Data"
                  route={routes.recordings()}
                  collapsed={collapsed}
                  hasSubItems={true}
                  selected={
                    !collapsed &&
                    sectionsByRoute[`/${route}`] ===
                      sectionsByRoute[routes.recordings()]
                  }
                />
                <DrawerNavSubItem
                  label="Recordings"
                  collapsed={collapsed}
                  route={routes.recordings()}
                  currentRoute={route}
                  icon={<IconMicrophone />}
                />
                <DrawerNavSubItem
                  label="Actions"
                  collapsed={collapsed}
                  route={routes.actions()}
                  currentRoute={route}
                  icon={<IconBolt />}
                />
                <DrawerNavSubItem
                  label="Context"
                  collapsed={collapsed}
                  route={routes.context()}
                  currentRoute={route}
                  icon={<IconBubbleText />}
                />
                <DrawerNavSubItem
                  label="Organizations"
                  collapsed={collapsed}
                  route={routes.organizations()}
                  currentRoute={route}
                  icon={<IconBuildingSkyscraper />}
                />
                <DrawerNavSubItem
                  label="People"
                  collapsed={collapsed}
                  route={routes.people()}
                  currentRoute={route}
                  icon={<IconUser />}
                />
                <DrawerNavSubItem
                  label="Pages"
                  collapsed={collapsed}
                  route={routes.pages()}
                  currentRoute={route}
                  icon={<IconFiles />}
                />
              </Box>
            </List>
            {false && <PageTree collapsed={collapsed} />}
            <ProfileController
              userCoreContact={userCoreContact}
              drawerWidth={drawerWidth}
              collapsed={collapsed}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              pl: '64px',
              pr: 0,
              py: 0,
              m: 0,
              pt: 0,
              overflow: 'hidden',
              zIndex: 2,
              background: (theme) => theme.palette.background.paper,
            }}
          >
            <UserErrorRemediation />
            <WorkspaceInviteBanner workspaceInvites={workspaceInvites} />
            {children}
          </Box>
        </Box>
      </>
    )
  )
}

const Ga4ContentGroup = {
  today: 'Home',
  prospects: 'Sales',
  opportunities: 'Sales',
  recordings: 'Meetings',
  marketing: 'Marketing',
  customers: 'Customers',
  pages: 'Pages',
  objectDetail: 'Sales',
  people: 'Data',
  pipelines: 'Sales',
  context: 'Data',
  reviewMeeting: 'Meetings',
  pageDetail: 'Pages',
  workspaceMeetingRecording: 'Meetings',
  workspaceLibrary: 'Meetings',
  library: 'Meetings',
  home: 'Home',
  settings: 'Data',
  actions: 'Data',
  organizations: 'Data',
  reportsPipeline: 'Sales',
  customerInbox: 'Customers',
  customersCurrent: 'Customers',
  product: 'Product',
  marketingContent: 'Marketing',
  marketingCampaigns: 'Marketing',
}

const ModernLayout = ({ children, window }) => {
  const location = useLocation()
  const route = useRouteName()
  const { currentUser } = useAuth()

  useEffect(() => {
    const path = location?.pathname
    const payload = {
      hitType: 'pageview',
      page: path,
      title: route,
      contentGroup1: Ga4ContentGroup[route] || 'Other',
    }
    if (currentUser) {
      payload.userId = currentUser.id
    }
    logger.dev({ payload })
    if (path) {
      try {
        ReactGA.send(payload)
      } catch (e) {
        logger.error('GA tracking error', e as Error, {
          location: path,
        })
      }
    }
  }, [location?.pathname, route])

  return (
    <DayProvider>
      <AvatarCacheProvider>
        <ModernLayoutInner window={window}>{children}</ModernLayoutInner>
      </AvatarCacheProvider>
    </DayProvider>
  )
}

export default ModernLayout
