import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useMutation, useQuery } from '@redwoodjs/web'
import { useState } from 'react'

import StickyNoteLineIcon from 'remixicon-react/StickyNoteLineIcon'
import { navigate, routes } from '@redwoodjs/router'
import { useAuth } from 'src/auth'
import toast from 'react-hot-toast'

const GET_PAGE_BY_ID_QUERY_FROM_DIALOG = gql`
  query GetPageByIdFromDialog($id: String!) {
    page(id: $id) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
    }
  }
`

const UPDATE_PAGE_MUTATION_FROM_DIALOG = gql`
  mutation UpdatePageMutationFromDialog(
    $id: String!
    $input: UpdatePageInput!
  ) {
    updatePage(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
    }
  }
`

const CREATE_PAGE_MUTATION_FROM_DIALOG = gql`
  mutation CreatePageMutationFromDialog($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
    }
  }
`

const PageManagementDialog = ({
  onClose,
  onRename,
  onCreate,
  onMove,
  onDelete,
  managePageData,
  selectedWorkspace,
}) => {
  const { currentUser: user } = useAuth()
  const [title, setTitle] = useState('New page')

  const { pageId, parentId, folders } = managePageData

  const [targetParentId, setTargetParentId] = useState(
    parentId || folders[0].id
  )

  useQuery(GET_PAGE_BY_ID_QUERY_FROM_DIALOG, {
    variables: { id: pageId },
    skip: !pageId || pageId === 'new',
    onCompleted: (pageInfoForManagementDialog) => {
      setTitle(pageInfoForManagementDialog.page.title)
    },
  })

  const [updatePage] = useMutation(UPDATE_PAGE_MUTATION_FROM_DIALOG)
  const [createPage] = useMutation(CREATE_PAGE_MUTATION_FROM_DIALOG)

  const handleCreatePage = async () => {
    toast.promise(
      createPage({
        variables: {
          input: {
            title,
            ownerEmail: user.email,
            contentHtml: '',
            publishedForUserAt: new Date().toISOString(),
            workspaceId: selectedWorkspace,
            contentJson: {
              type: 'doc',
              content: [
                {
                  type: 'title',
                  content: [{ type: 'text', text: title }],
                },
              ],
            },
          },
        },
      }),
      {
        loading: 'Creating page...',
        success: (newPage) => {
          const node = {
            id: newPage.data.createPage.id,
            name: newPage.data.createPage.title,
          }
          onCreate({ parentId: targetParentId, node })
          navigate(routes.pageDetail({ id: newPage.data.createPage.id }))
          onClose()
          return `Page '${newPage.data.createPage.title}' created successfully!`
        },
        error: (error) => `Failed to create page: ${error.message}`,
      }
    )
  }

  const handleUpdatePage = async () => {
    await updatePage({
      variables: {
        id: pageId,
        input: {
          title,
        },
      },
    })

    onRename({ id: pageId, name: title })

    if (parentId != targetParentId) {
      onMove({ dragIds: [pageId], parentId: targetParentId })
    }
    onClose()
  }
  return (
    <Dialog
      open={!!pageId}
      onClose={onClose}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          width: '500px',
        }}
      >
        <StickyNoteLineIcon style={{ height: '24px', marginRight: '8px' }} />
        {title}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth={true}
            label="Title"
          />
        </Box>

        <Box sx={{ py: 2 }}>
          <FormControl fullWidth={true}>
            <InputLabel id="folder-label">Folder</InputLabel>
            <Select
              value={targetParentId}
              onChange={(e) => setTargetParentId(e.target.value)}
              fullWidth={true}
              label="Folder"
              labelId="folder-label"
            >
              {folders.map((f, index) => (
                <MenuItem
                  key={`folder_${index}_${f.id}`}
                  value={f.id}
                >
                  {f.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          sx={{ mt: 2 }}
          onClick={async () => {
            if (pageId === 'new') {
              await handleCreatePage()
            } else {
              await handleUpdatePage()
            }
          }}
        >
          {pageId === 'new' ? 'Create page' : 'Update page'}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default PageManagementDialog
