import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material'
import { RiDeleteBinLine, RiFolderLine } from '@remixicon/react'
import { useState } from 'react'
import Row from '../Row/Row'

const PageFolderManagementDialog = ({
  folderData,
  onRename,
  onClose,
  onDelete,
}) => {
  const [title, setTitle] = useState(folderData.name)
  return (
    <Dialog
      open={folderData?.id !== null}
      onClose={onClose}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          width: '500px',
        }}
      >
        <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Row>
            <RiFolderLine style={{ height: '24px', marginRight: '8px' }} />
            Edit folder
          </Row>
          {!folderData.id.includes('USER') && (
            <IconButton
              onClick={() => {
                onDelete({ id: folderData.id })
                onClose()
              }}
            >
              <RiDeleteBinLine style={{ height: '18px' }} />
            </IconButton>
          )}
        </Row>
      </DialogTitle>
      <DialogContent sx={{ width: '500px' }}>
        <TextField
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth={true}
          label="Title"
          sx={{ mt: 2, mb: 3 }}
        />
        <Button
          variant="contained"
          fullWidth={true}
          color="primary"
          onClick={() => {
            onRename({ id: folderData.id, name: title })
            onClose()
          }}
        >
          Update folder
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default PageFolderManagementDialog
