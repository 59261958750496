import { Chip } from '@mui/material'
import {
  RiCheckLine,
  RiClockwiseLine,
  RiMailUnreadFill,
  RiRefreshLine,
} from '@remixicon/react'

const actionStatusMap = {
  COMPLETED: {
    label: 'Completed',
    color: 'success',
    icon: <RiCheckLine />,
  },
  READ: {
    label: 'In Progress',
    color: 'info',
    icon: <RiRefreshLine />,
  },
  DISMISSED: {
    label: 'Dismissed',
    color: 'error',
    icon: <RiCheckLine />,
  },
  REDUNDANT: {
    label: 'Redundant',
    color: 'warning',
    icon: <RiCheckLine />,
  },
  SNOOZED: {
    label: 'Snoozed',
    color: 'info',
    icon: <RiClockwiseLine />,
  },
  UNREAD: {
    label: 'Unread',
    color: 'info',
    icon: <RiMailUnreadFill />,
  },
}

const ActionStatusChip = ({ status }) => {
  if (!status) return null
  const statusObject = actionStatusMap[status.id]
  return (
    <Chip
      size="small"
      icon={statusObject.icon}
      label={statusObject.label}
      color={statusObject.color}
      variant="outlined"
      sx={{
        width: '100%',
        fontSize: '11px',
        fontWeight: 600,
        letterSpacing: '-0.2px',
        borderColor: (theme) => theme.palette[statusObject.color].light,
        color: (theme) => theme.palette[statusObject.color].main,
        '.remixicon': {
          height: '16px',
          width: '16px',
          m: 0,
        },
      }}
    />
  )
}

export default ActionStatusChip
