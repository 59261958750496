import { useContext } from 'react'

import { Box, FormControl, ListItemText, MenuItem, Select } from '@mui/material'
import { RiGroupLine } from '@remixicon/react'

import { DayContext } from 'src/lib/dayContext'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import Row from '../../Row/Row'
import { pipelineFilterWidths } from '../Pipeline'

const PipelineOwnerFilter = ({ ownerList, onChange, filters }) => {
  const { workspacePeople } = useContext(DayContext)

  const getDisplayName = (owner: string): string => {
    const person = workspacePeople.find((person) => person.email === owner)
    return person ? `${person?.fullName}` : owner
  }

  return (
    <FormControl
      sx={{
        width: pipelineFilterWidths,
        mr: 1,
        borderRadius: '4px',
        height: '32px',
        p: 0,
        '& .MuiSelect-select': {
          p: 0,
          borderRadius: '4px',
          height: '30px',
          alignItems: 'center',
          border: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Select
        id="owner-select"
        value={filters?.owner || 'allOwners'}
        onChange={onChange}
        variant="standard"
        size="small"
        disableUnderline={true}
      >
        <MenuItem
          key="allOwners"
          value="allOwners"
          sx={{ px: 0 }}
        >
          <Row sx={{ height: '30px' }}>
            <Box
              sx={{
                height: '20px',
                width: '20px',
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 1,
                '& .remixicon': {
                  color: (theme) => theme.palette.text.secondary,
                  height: '16px',
                  width: '16px',
                },
              }}
            >
              <RiGroupLine />
            </Box>
            <ListItemText primary="All owners" />
          </Row>
        </MenuItem>
        {ownerList.map((owner, index) => (
          <MenuItem
            key={index}
            value={owner}
            sx={{ px: 0 }}
          >
            <Row sx={{ height: '30px' }}>
              <Box sx={{ mx: 1 }}>
                <ContactAvatar
                  size={20}
                  email={owner}
                  borderRadius={2}
                />
              </Box>
              <ListItemText primary={getDisplayName(owner)} />
            </Row>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PipelineOwnerFilter
