import { useContext, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  RiAddLine,
  RiArrowLeftSLine,
  RiBarChart2Fill,
  RiCheckLine,
  RiCircleFill,
  RiCloseLine,
  RiCurrencyLine,
  RiDeleteBinLine,
} from '@remixicon/react'
import { IconBubbleText } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import { getDomain } from 'tldts'

import { useMutation, useQuery } from '@redwoodjs/web'

import AiFeature from 'src/components/AiFeature/AiFeature'
import ContactTile from 'src/components/ContactTile/ContactTile'
import ContextEditSidebar from 'src/components/ContextEditSidebar/ContextEditSidebar'
import OpportunityActions from 'src/components/Opportunities/OpportunityActions/OpportunityActions'
import OpportunityNotes from 'src/components/Opportunities/OpportunityNotes/OpportunityNotes'
import OrganizationChip from 'src/components/Organizations/OrganizationChip/OrganizationChip'
import OpportunityRolesList from 'src/components/Pipeline/OpportunityRolesList/OpportunityRolesList'
import Row from 'src/components/Row/Row'
import SidebarSlack from 'src/components/Sidebar/SidebarSlack/SidebarSlack'
import SourceChip from 'src/components/SourceChip/SourceChip'
import StageChip from 'src/components/StageChip/StageChip'
import WorkspaceMemberSelect from 'src/components/WorkspaceMemberSelect/WorkspaceMemberSelect'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import { actionChipStyle } from '../Sidebar'

const UPDATE_OPPORTUNITY_FROM_SIDEBAR = gql`
  mutation sidebarUpdateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

const DELETE_OPPORTUNITY = gql`
  mutation sidebarDeleteOpportunity(
    $id: String!
    $workspaceId: String!
    $pipelineId: String!
  ) {
    deleteOpportunity(
      id: $id
      workspaceId: $workspaceId
      pipelineId: $pipelineId
    ) {
      id
      objectType
    }
  }
`

const GET_ORGANIZATION_FOR_OPP_SIDEBAR = gql`
  query SidebarGetOrganizationForOppSidebar(
    $workspaceId: String!
    $domain: String!
  ) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      domain
      colors {
        id
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorDarkMuted
        colorLightMuted
      }
      photos {
        id
        square
      }
    }
  }
`

const GET_OPPORTUNITY_FOR_SIDEBAR = gql`
  query SidebarGetOpportunityForSidebar($workspaceId: String!, $id: String!) {
    workspaceOpportunity(workspaceId: $workspaceId, id: $id) {
      id
      title
      ownerEmail
      expectedCloseDate
      expectedRevenue
      status
      modelUpdatedAt
      roles {
        personEmail
        roles
      }
      goals {
        content
        source {
          sourceId
          sourceType
        }
      }
      impactOfChange {
        content
        source {
          sourceId
          sourceType
        }
      }
      budgetAndTimeline {
        content
        source {
          sourceId
          sourceType
        }
      }
      stage
      stages
      recommendedStage {
        expectedCloseDate
        expectedRevenue
        proofOfPayment
        readyToProgress
        reasoningforStage
        stageId
        nextStageEntranceCriteriaStatus {
          criteria
          reasoning
          met
        }
      }
      challenges {
        challenge
        solution
        source {
          sourceId
          sourceType
        }
      }
      risks {
        content
        source {
          sourceId
          sourceType
        }
      }
      competition {
        content
        source {
          sourceId
          sourceType
        }
      }
      decisionProcess {
        content
        source {
          sourceId
          sourceType
        }
      }
    }
  }
`

const buttonWidth = (hasRevenue: boolean) => {
  const containerWidth = 624
  if (hasRevenue) {
    return `${(containerWidth - 184) / 3}px`
  }
  return `${(containerWidth - 184) / 2}px`
}

const sections = [
  { title: 'Competition', field: 'competition' },
  { title: 'Process & Timeline', field: 'decisionProcess' },
  { title: 'Goals', field: 'goals' },
  { title: 'Impact of Change', field: 'impactOfChange' },
  { title: 'Budget', field: 'budgetAndTimeline' },
  { title: 'Risks', field: 'risks' },
]

const EntranceCriteriaStatus = ({ status }) => {
  return (
    <Chip
      label={status.criteria}
      variant="filled"
      color={status.met ? 'success' : 'default'}
      icon={status.met ? <RiCheckLine size={16} /> : <RiCloseLine size={16} />}
      size="medium"
      sx={{
        fontSize: '12px',
        fontWeight: 500,
        mb: 1,
        pl: 1,
        width: '100%',
        borderRadius: '4px',
      }}
    />
  )
}

const SidebarLayoutOpportunity = ({ crmObject, setOpen }) => {
  const theme = useTheme()
  const { selectedWorkspace } = useContext(DayContext)
  const confirm = useConfirm()
  const [notesRefetchKey, setNotesRefetchKey] = useState(uuidv4())

  const onUpdate = crmObject?.properties?.refetch ?? (() => {})

  const [editing, setEditing] = useState(false)
  const [stageStatusExpanded, setStageStatusExpanded] = useState(false)
  const [noteData, setNoteData] = useState(null)

  const passedOpportunity = useMemo(() => {
    return {
      id: crmObject.objectId,
      title: crmObject.properties.title,
      primaryPerson: crmObject.properties.primaryPerson?.objectId,
      type: crmObject.properties.type,
      ownerEmail: crmObject.properties.ownerEmail,
      expectedCloseDate: crmObject.properties.expectedCloseDate,
      currentStatus: crmObject.properties.currentStatus,
      expectedRevenue: crmObject.properties.expectedRevenue,
      domain: crmObject.properties.domain,
      hasRevenue: crmObject.properties.hasRevenue,
      pipelineId: crmObject.properties.pipelineId,
      workspaceId: crmObject.properties.workspaceId,
      opportunityTypes: crmObject.properties.opportunityTypes,
      roles: crmObject.properties.roles,
      createdAt: crmObject.properties.createdAt,
      updatedAt: crmObject.properties.updatedAt,
      position: crmObject.properties.position,
      stageId: crmObject.properties.stageId,
      status: crmObject.properties.status,
      stage: crmObject.properties.stage,
      stages: crmObject.properties.stages,
    }
  }, [crmObject])

  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY_FROM_SIDEBAR)
  const [deleteOpportunity] = useMutation(DELETE_OPPORTUNITY)
  const [opportunityToSave, setOpportunityToSave] = useState(passedOpportunity)
  const domain = passedOpportunity?.domain
    ? getDomain(`http://${passedOpportunity.domain}`)
    : null
  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_FOR_OPP_SIDEBAR,
    {
      variables: {
        workspaceId: selectedWorkspace,
        domain,
      },
      skip: !domain || !selectedWorkspace,
    }
  )

  const {
    data: opportunityData,
    loading: opportunityLoading,
    refetch,
  } = useQuery(GET_OPPORTUNITY_FOR_SIDEBAR, {
    variables: {
      workspaceId: selectedWorkspace,
      id: crmObject.objectId,
    },
    skip: !selectedWorkspace || !crmObject.objectId,
    onCompleted: ({ workspaceOpportunity }) => {
      logger.dev({ workspaceOpportunity })
    },
  })
  const opportunity = useMemo(() => {
    return { ...passedOpportunity, ...opportunityData?.workspaceOpportunity }
  }, [opportunityData, passedOpportunity])

  const organization = organizationData?.workspaceOrganization

  const closeAndRefetch = () => {
    onUpdate()
    setOpen(false)
  }

  const initNote = () => {
    setNoteData({
      new: true,
      parentReferenceKey: `${NativeObjectTypes.Opportunity} : ${opportunity.id} : root`,
      title: opportunity.title,
    })
  }

  const handleUpdateOpportunity = async () => {
    const oppToSave = {
      workspaceId: opportunityToSave.workspaceId,
      id: opportunityToSave.id,
      pipelineId: opportunityToSave.pipelineId,
      title: opportunityToSave.title,
      expectedCloseDate: opportunityToSave.expectedCloseDate,
      expectedRevenue: opportunityToSave.expectedRevenue,
      ownerEmail: opportunityToSave.ownerEmail,
      type: opportunityToSave.type,
    }
    logger.dev({ oppToSave, opportunityToSave })
    toast.promise(updateOpportunity({ variables: { input: oppToSave } }), {
      loading: 'Updating Opportunity...',
      success: () => {
        setEditing(false)
        onUpdate()
        return 'Opportunity Updated!'
      },
      error: 'Error Updating Opportunity',
    })
  }

  const handleDeleteOpportunity = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to delete this opportunity?',
      })

      await toast.promise(
        deleteOpportunity({
          variables: {
            id: crmObject.objectId,
            workspaceId: crmObject.properties.workspaceId,
            pipelineId: crmObject.properties.pipelineId,
          },
        }),
        {
          loading: 'Deleting Opportunity...',
          success: () => {
            closeAndRefetch()
            return 'Opportunity Deleted!'
          },
          error: 'Error Deleting Opportunity',
        }
      )
    } catch (error) {
      console.error('Failed to delete the opportunity:', error)
    }
  }

  return (
    <>
      <Row
        sx={{
          justifyContent: 'space-between',
          pl: 3,
          pr: '18px',
          height: '72px',
        }}
      >
        <Row
          gap={1}
          sx={{ width: '410px', overflow: 'hidden', height: '36px' }}
        >
          <RiBarChart2Fill
            size={24}
            color={organization?.colorVibrant || theme.palette.primary.main}
            style={{ transition: 'all 0.3s ease-in-out' }}
          />
          <Typography
            variant="h2"
            sx={{
              p: 0,
              m: 0,
              width: '264px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flexShrink: 1,
            }}
          >
            {opportunity.title || 'Opportunity'}
          </Typography>
        </Row>
        <Row gap={1}>
          <AiFeature
            featureKey="opportunities"
            objectId={opportunity?.id}
            variant="outlined"
            lastUpdated={opportunity?.modelUpdatedAt}
            size={16}
          />
          <Chip
            clickable={true}
            icon={<IconBubbleText size={16} />}
            label="Add context"
            variant="outlined"
            sx={actionChipStyle}
            onClick={(e) => {
              e.stopPropagation()
              initNote()
            }}
          />
          {noteData && (
            <ContextEditSidebar
              context={noteData}
              onClose={() => setNoteData(null)}
              onUpdate={() => {
                onUpdate()
                setNotesRefetchKey(uuidv4())
                refetch()
              }}
              onInit={initNote}
              title={opportunity.title}
            />
          )}
          {opportunity.domain && (
            <OrganizationChip
              size="small"
              domain={opportunity.domain}
            />
          )}
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setOpen(false)
            }}
          >
            <RiCloseLine />
          </IconButton>
        </Row>
      </Row>
      <Box>
        {opportunity && (
          <Box>
            {!editing ? (
              <>
                <Row
                  sx={{
                    justifyContent: 'space-between',
                    mb: 0,
                    px: 3,
                    py: '12px',
                    height: '72px',
                    background: (theme) => theme.palette.background.default,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderLeft: 'none',
                    borderRight: 'none',
                  }}
                >
                  <Box
                    sx={{
                      width: '184px',
                      flexShrink: 0,
                    }}
                  >
                    <ContactTile
                      showSidebar={false}
                      email={opportunity.ownerEmail}
                    />
                  </Box>
                  {opportunity.hasRevenue && opportunity.expectedRevenue && (
                    <Row
                      sx={{
                        justifyContent: 'center',
                        width: buttonWidth(opportunity.hasRevenue),
                        flexShrink: 0,
                        borderRight: (theme) =>
                          `1px solid ${theme.palette.divider}`,
                        height: '48px',
                      }}
                    >
                      <Row
                        sx={{
                          width: '100%',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '14px',
                            letterSpacing: '-0.6px',
                          }}
                        >
                          {`$${opportunity.expectedRevenue.toLocaleString()}`}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '140%',
                            letterSpacing: '-0.6px',
                            opacity: 0.7,
                          }}
                        >
                          Annual Revenue
                        </Typography>
                      </Row>
                    </Row>
                  )}
                  <Row
                    sx={{
                      justifyContent: 'center',
                      width: buttonWidth(opportunity.hasRevenue),
                      flexShrink: 0,
                    }}
                  >
                    <Row
                      sx={{
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '-0.6px',
                        }}
                      >
                        {`${dayjs(opportunity.expectedCloseDate).format(
                          'M/D/YY'
                        )}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '140%',
                          letterSpacing: '-0.6px',
                          opacity: 0.7,
                        }}
                      >
                        Expected Close Date
                      </Typography>
                    </Row>
                  </Row>
                  <Row
                    sx={{
                      justifyContent: 'right',
                      width: buttonWidth(opportunity.hasRevenue),
                      flexShrink: 0,
                    }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation()
                        setEditing(true)
                      }}
                      sx={{ width: '100%' }}
                    >
                      Update
                    </Button>
                  </Row>
                </Row>
                <Box
                  sx={{
                    height: 'calc(100vh - 172px + 24px)',
                    overflowY: 'auto',
                  }}
                >
                  <Box sx={{ px: 3, my: 3 }}>
                    <Row sx={{ justifyContent: 'space-between' }}>
                      <Typography variant="h2">Status</Typography>

                      <Row gap={2}>
                        <StageChip
                          stage={opportunity?.stage}
                          opportunityId={opportunity.id}
                          allowChange={true}
                          stages={opportunity?.stages}
                          sx={{
                            borderRadius: '100px',
                            height: '28px',
                          }}
                          onUpdate={() => {
                            onUpdate()
                            refetch()
                          }}
                        />
                        {opportunity?.recommendedStage?.stageId &&
                          opportunity?.recommendedStage?.stageId !==
                            opportunity.stageId && (
                            <>
                              {!opportunity?.recommendedStage
                                ?.readyToProgress ? (
                                <>
                                  {opportunity?.recommendedStage
                                    ?.nextStageEntranceCriteriaStatus?.length >
                                    1 && (
                                    <Tooltip
                                      title={`${
                                        opportunity.recommendedStage?.nextStageEntranceCriteriaStatus?.filter(
                                          (status) => status.met
                                        ).length
                                      }/${
                                        opportunity.recommendedStage
                                          ?.nextStageEntranceCriteriaStatus
                                          ?.length
                                      } criteria met (click ${
                                        stageStatusExpanded
                                          ? 'to close'
                                          : 'for details'
                                      })`}
                                      arrow={true}
                                    >
                                      <Box
                                        onClick={() => {
                                          setStageStatusExpanded(
                                            (prev) => !prev
                                          )
                                        }}
                                      >
                                        <Row
                                          sx={{
                                            justifyContent: 'right',
                                          }}
                                        >
                                          {opportunity.recommendedStage?.nextStageEntranceCriteriaStatus?.map(
                                            (status, index) => (
                                              <Box key={`status-${index}`}>
                                                <RiCircleFill
                                                  size={20}
                                                  color={
                                                    status.met
                                                      ? theme.palette.success
                                                          .main
                                                      : theme.palette.divider
                                                  }
                                                />
                                              </Box>
                                            )
                                          )}
                                        </Row>
                                      </Box>
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                      </Row>
                    </Row>

                    {opportunity?.recommendedStage?.readyToProgress && (
                      <Typography
                        variant="body1"
                        sx={{ mt: 2 }}
                      >
                        {opportunity.recommendedStage?.reasoningforStage}
                      </Typography>
                    )}

                    {stageStatusExpanded &&
                      !opportunity?.recommendedStage?.readyToProgress && (
                        <Box
                          onClick={() => setStageStatusExpanded(false)}
                          sx={{ cursor: 'pointer', mt: 2 }}
                        >
                          <Typography>
                            {opportunity.recommendedStage?.nextStageEntranceCriteriaStatus?.map(
                              (status, index) => (
                                <EntranceCriteriaStatus
                                  key={`status-${index}`}
                                  status={status}
                                />
                              )
                            )}
                          </Typography>
                        </Box>
                      )}
                    {opportunity.status && (
                      <List>
                        {opportunity.status.map((status, index) => (
                          <ListItem
                            key={`status-${index}`}
                            sx={{
                              py: 1,
                              px: 0,
                              '&:last-child': {
                                borderBottom: 'none',
                              },
                            }}
                            disableGutters={true}
                          >
                            <ListItemIcon
                              sx={{ width: '24px', minWidth: '24px' }}
                            >
                              <RiCircleFill
                                size={10}
                                color={theme.palette.primary.main}
                              />
                            </ListItemIcon>
                            <ListItemText primary={status} />
                          </ListItem>
                        ))}
                      </List>
                    )}
                    <Box sx={{ mb: 2 }}>
                      <OpportunityActions
                        opportunityId={opportunity.id}
                        actions={opportunity.actions}
                      />
                    </Box>

                    <OpportunityRolesList
                      id={opportunity.id}
                      opportunity={opportunity}
                      showHeader={true}
                    />
                    {opportunity.challenges?.length > 0 && (
                      <>
                        <Typography
                          variant="h2"
                          sx={{ my: 2 }}
                        >
                          Challenges & Solutions
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                            mb: 5,
                            border: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                            borderRadius: '8px',
                            background: (theme) =>
                              theme.palette.background.paper,
                            p: 1,
                          }}
                        >
                          <TableContainer>
                            <Table
                              sx={{
                                '& .MuiTableCell-head': {
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  pt: '4px',
                                  pb: '6px',
                                },
                                '& .MuiTableCell-root': {
                                  border: 'none',
                                },
                                '& .MuiTableBody-root .MuiTableRow-root': {
                                  borderBottom: (theme) =>
                                    `1px solid ${theme.palette.divider}`,
                                  '&:last-child': {
                                    borderBottom: 'none',
                                  },
                                },
                                '& .MuiTableHead-root .MuiTableRow-root': {
                                  borderBottom: (theme) =>
                                    `1px solid ${theme.palette.divider}`,
                                },
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Challenge</TableCell>
                                  <TableCell>Proposed Solution</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {opportunity?.challenges?.map((item, index) => (
                                  <TableRow key={`challenge-${index}`}>
                                    <TableCell
                                      sx={{
                                        fontWeight: 500,
                                        fontSize: '0.8rem',
                                        verticalAlign: 'top',
                                      }}
                                    >
                                      {item.challenge}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontStyle: 'italic',
                                        fontSize: '0.8rem',
                                        verticalAlign: 'top',
                                      }}
                                    >
                                      {item.solution}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </>
                    )}
                    {sections.map((section, index) => {
                      return (
                        opportunity[section.field] &&
                        opportunity[section.field].content?.length > 0 && (
                          <Box
                            key={index}
                            sx={{ mb: 5 }}
                          >
                            <Row sx={{ justifyContent: 'space-between' }}>
                              <Typography
                                variant="h2"
                                sx={{ mb: 2 }}
                              >
                                {section.title}
                              </Typography>
                              <Row>
                                {opportunity[section.field]?.source?.map(
                                  (source, sourceIndex) => (
                                    <SourceChip
                                      key={`${source.id}-${source.type}-${section.field}-${index}-${sourceIndex}`}
                                      source={source}
                                    />
                                  )
                                )}
                              </Row>
                            </Row>

                            <List
                              sx={{ p: 0 }}
                              disablePadding={true}
                            >
                              {opportunity[section.field]?.content?.map(
                                (item, itemIndex) => (
                                  <ListItem
                                    key={`${section.field}-${itemIndex}-${item}`}
                                    sx={{
                                      py: 0,
                                      px: 0,
                                      '&:last-child': {
                                        borderBottom: 'none',
                                      },
                                    }}
                                    disableGutters={true}
                                  >
                                    <ListItemIcon
                                      sx={{ width: '24px', minWidth: '24px' }}
                                    >
                                      <RiCircleFill
                                        size={10}
                                        color={theme.palette.primary.main}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={item} />
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Box>
                        )
                      )
                    })}
                    <Row sx={{ justifyContent: 'space-between' }}>
                      <Typography variant="h2">Context</Typography>
                      <Box>
                        <Button
                          startIcon={<RiAddLine size={16} />}
                          variant="text"
                          size="small"
                          onClick={initNote}
                        >
                          Add context
                        </Button>
                      </Box>
                    </Row>
                    <OpportunityNotes
                      opportunityId={opportunity.id}
                      setNoteData={setNoteData}
                      key={notesRefetchKey}
                    />
                    {opportunity.domain && (
                      <Box sx={{ mt: 5 }}>
                        <SidebarSlack orgId={opportunity.domain} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <Box sx={{ px: 3 }}>
                <Button
                  onClick={() => setEditing(false)}
                  color="primary"
                  sx={{ mb: 3 }}
                  disableElevation={true}
                  startIcon={<RiArrowLeftSLine />}
                >
                  Back to view opportunity
                </Button>
                <TextField
                  label="Title"
                  value={opportunityToSave.title}
                  fullWidth={true}
                  onChange={(e) =>
                    setOpportunityToSave({
                      ...opportunityToSave,
                      title: e.target.value,
                    })
                  }
                  sx={{ mb: 3 }}
                />

                {opportunity?.opportunityTypes ? (
                  <Autocomplete
                    options={opportunity?.opportunityTypes}
                    value={opportunityToSave.type}
                    freeSolo={true}
                    onChange={(e, newValue) => {
                      logger.dev({ e, newValue })
                      setOpportunityToSave({
                        ...opportunityToSave,
                        type: newValue,
                      })
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: 3 }}
                        {...params}
                        onChange={(e) => {
                          logger.dev({ e })
                          setOpportunityToSave({
                            ...opportunityToSave,
                            type: e.target.value,
                          })
                        }}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    label="Type"
                    value={opportunityToSave.type}
                    fullWidth={true}
                    sx={{ mb: 3 }}
                  />
                )}
                {opportunityToSave.ownerEmail ? (
                  <FormControl fullWidth={true}>
                    <InputLabel
                      sx={{ display: 'none' }}
                      id="owner"
                    >
                      {'Owner'}
                    </InputLabel>
                    <TextField
                      id="owner"
                      label="Owner"
                      fullWidth={true}
                      InputProps={{
                        sx: {
                          height: '52px',
                          width: '100%',
                          '& .MuiInputBase-input': {
                            display: 'none !important',
                          },
                        },
                        endAdornment: (
                          <IconButton
                            sx={{ borderRadius: '2px', p: '2px' }}
                            onClick={() =>
                              setOpportunityToSave({
                                ...opportunityToSave,
                                ownerEmail: '',
                              })
                            }
                          >
                            <RiCloseLine />
                          </IconButton>
                        ),
                        startAdornment: (
                          <Box
                            sx={{
                              width: '572px',
                              flexShrink: 0,
                            }}
                          >
                            <ContactTile
                              showSidebar={false}
                              email={opportunityToSave.ownerEmail}
                            />
                          </Box>
                        ),
                      }}
                    />
                  </FormControl>
                ) : (
                  <WorkspaceMemberSelect
                    onSelect={(selected) => {
                      setOpportunityToSave({
                        ...opportunityToSave,
                        ownerEmail: selected[0].email,
                      })
                    }}
                    value={
                      opportunityToSave.ownerEmail
                        ? [
                            {
                              email: opportunityToSave.ownerEmail,
                              isDefaultOwner: false,
                              status: 'ACTIVE',
                            },
                          ]
                        : []
                    }
                    label={'Owner'}
                  />
                )}
                <Row
                  sx={{ my: 3 }}
                  gap={2}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expected Close Date"
                      value={dayjs(opportunity.expectedCloseDate)}
                      onChange={(e) => {
                        setOpportunityToSave({
                          ...opportunityToSave,
                          expectedCloseDate: e.toISOString(),
                        })
                      }}
                      sx={{ width: '256px', flexShrink: 1 }}
                    />
                  </LocalizationProvider>

                  {opportunityToSave.hasRevenue ? (
                    <Row>
                      <TextField
                        id="expectedRevenue"
                        label="Expected Amount"
                        variant="outlined"
                        type="number"
                        fullWidth={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <Tooltip
                              title="Remove revenue from pipeline"
                              placement="top"
                              arrow={true}
                            >
                              <IconButton
                                sx={{ p: '4px', borderRadius: '2px', ml: 1 }}
                                onClick={() =>
                                  confirm({
                                    description:
                                      'Are you sure you want to remove revenue from this Pipeline?.',
                                  }).then(() => {
                                    setOpportunityToSave({
                                      ...opportunityToSave,
                                      hasRevenue: false,
                                    })
                                  })
                                }
                              >
                                <RiCloseLine />
                              </IconButton>
                            </Tooltip>
                          ),
                        }}
                        value={
                          typeof opportunityToSave.expectedRevenue === 'number'
                            ? opportunityToSave.expectedRevenue
                            : 0
                        }
                        onChange={(e) => {
                          const expectedRevenue = parseFloat(e.target.value)
                          setOpportunityToSave({
                            ...opportunityToSave,
                            expectedRevenue,
                          })
                        }}
                      />
                    </Row>
                  ) : (
                    <Tooltip
                      title="Add revenue to pipeline"
                      placement="top"
                      arrow={true}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          confirm({
                            description:
                              'Adding revenue to this Opportunity will turn revenue reporting on for this pipeline.',
                          }).then(() => {
                            setOpportunityToSave({
                              ...opportunityToSave,
                              hasRevenue: true,
                            })
                          })
                        }
                        fullWidth={true}
                      >
                        <RiCurrencyLine style={{ marginRight: '8px' }} />
                        Add revenue
                      </Button>
                    </Tooltip>
                  )}
                </Row>
                <Row gap={2}>
                  <Button
                    onClick={handleUpdateOpportunity}
                    color="secondary"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ flexShrink: 1 }}
                    disableElevation
                  >
                    Update Opportunity
                  </Button>

                  <Tooltip
                    title="Delete Opportunity"
                    placement="top"
                    arrow={true}
                  >
                    <IconButton
                      onClick={handleDeleteOpportunity}
                      sx={{ p: '4px', borderRadius: '2px' }}
                    >
                      <RiDeleteBinLine />
                    </IconButton>
                  </Tooltip>
                </Row>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default SidebarLayoutOpportunity
