import { isWorkAccountHealthy } from 'src/components/WorkAccount/workAccountUtil'
import { logger } from './logger'

const isCoreContactComplete = (coreContact) => {
  return coreContact.firstName && coreContact.lastName
}

export const isWorkAccountReady = ({ workAccount }) => {
  if (!workAccount) return false
  return isWorkAccountHealthy({ workAccount })
}

const isWorkspaceReady = (workspace) => {
  return workspace?.status === 'ACTIVE'
}

const hasEmailSharingSettings = (
  workAccountWorkspaceConnections,
  workspace
) => {
  if (
    !workAccountWorkspaceConnections ||
    workAccountWorkspaceConnections?.length === 0 ||
    !workspace
  ) {
    logger.warn('no workAccountWorkspaceConnections')
    return false
  }

  return workAccountWorkspaceConnections?.some((connectedWorkAccount) => {
    return connectedWorkAccount.connectedWorkspaces?.find(
      (connectedWorkspace) => {
        // todo: check the workspace id matches target workspace and the rules are sufficient

        return (
          connectedWorkspace.workspaceId === workspace.id &&
          connectedWorkspace.sharingRules?.length >= 6
        )
      }
    )
  })
}

const hasRecordingSettings = (workAccounts) => {
  const enabled = workAccounts.filter((workAccount) => {
    return workAccount?.calendarAutoRecordSettings?.mode != 'NONE'
  })

  return enabled.length === workAccounts.length
}

export const onboardingStatus = ({
  coreContact,
  workAccounts,
  workspace,
  workAccountWorkspaceConnections,
  declinedToRecord = false,
}) => {
  const workAccountsToSkip = workAccounts.filter((workAccount) => {
    return [
      'taylor.lint@gmail.com',
      'taylor@swantide.com',
      'ryan@swantide.com',
      'andrew@swantide.com',
      'matthew@swantide.com',
      'khakie@swantide.com',
      'megan@circle.co',
    ].includes(workAccount.email)
  })

  if (workAccountsToSkip.length > 0) {
    return {
      onboardingStep: 5,
      isComplete: true,
    }
  }

  let farthestStep = 0
  if (coreContact && isCoreContactComplete(coreContact)) {
    farthestStep = 1
    if (hasEmailSharingSettings(workAccountWorkspaceConnections, workspace)) {
      farthestStep = 4

      if (hasRecordingSettings(workAccounts) || declinedToRecord) {
        farthestStep = 5
      }
    } else {
      if (isWorkspaceReady(workspace)) {
        farthestStep = 3
      } else if (
        isWorkAccountReady({
          workAccount: workAccounts?.[0],
        })
      ) {
        farthestStep = 2
      }
    }
  } else {
    farthestStep = 0
  }
  return {
    onboardingStep: farthestStep,
    isComplete: farthestStep > 3,
  }
}
