import { useContext } from 'react'

import {
  Select,
  MenuItem,
  FormControl,
  lighten,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import { RiCommunityFill, RiSettings2Line } from '@remixicon/react'

import { navigate, routes } from '@redwoodjs/router'

import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'

export function getWorkspaceLogo(workspace) {
  if (workspace.isDefault) return `${process.env.HOST}/logos/logo-blue.png`

  return `${process.env.HOST}/logos/logo-blue-inverse.png`

  /* later if ever:
  if (!workspace.name || workspace.name.length < 2) {
    return `${process.env.HOST}/logos/logo-blue-inverse.png`
  }

  const charSum = workspace.name.charCodeAt(0) + workspace.name.charCodeAt(1)
  const logoIndex = (charSum % 24) + 1

  return `${process.env.HOST}/logos/Day ${logoIndex}.png`
  */
}

export default function WorkspaceDropdown({ collapsed }) {
  const {
    selectedWorkspace,
    setSelectedWorkspace,
    workspaces,
    workAccountWorkspaceConnections,
  } = useContext(DayContext)

  const handleChange = (event) => {
    if (event.target.value === 'CREATE') {
      navigate(routes.workspaceCreate())
    } else {
      setSelectedWorkspace(event.target.value)
      if (
        workAccountWorkspaceConnections?.some(
          ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
        )
      ) {
        navigate(routes.workspaceLibrary({ workspaceId: event.target.value }))
      } else {
        navigate(routes.library())
      }
    }
  }

  return selectedWorkspace && workspaces?.length > 0 ? (
    <FormControl
      variant="outlined"
      sx={{
        width: '100%',
        height: '56px',
        overflow: 'hidden',
        '&:hover': {
          '& .MuiOutlinedInput-root': {
            color: (theme) => theme.palette.primary.dark,
            borderRadius: 0,
          },
          '& .MuiSelect-icon': {
            color: (theme) => theme.palette.primary.dark,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => `${theme.palette.primary.dark} !important`,
          },
          '& .MuiInputLabel-root': {
            color: (theme) => theme.palette.primary.dark,
          },
        },
      }}
    >
      <Select
        labelId="workspace-select-label"
        id="workspace-select"
        value={selectedWorkspace}
        MenuProps={{
          id: 'workspace-select-menu',
          sx: {
            '& .MuiMenu-paper': {
              width: '400px',
              minWidth: '400px',
            },
          },
        }}
        renderValue={(value) => {
          const workspace = workspaces.find((w) => w.id === value)

          if (!workspace) {
            return null
          }

          return (
            <Row
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Box>
                {workspace?.domains?.length > 0 &&
                workspace.domains[0].domain ? (
                  <DomainAvatar
                    domain={workspace.domains[0].domain}
                    size={36}
                    borderRadius={2}
                    sx={{
                      height: '36px',
                      width: '36px',
                      borderRadius: '2px',
                      flexShrink: 0,
                      m: '12px',
                    }}
                  />
                ) : (
                  <Box
                    component="img"
                    src={getWorkspaceLogo(workspace)}
                    alt={workspace.name}
                    sx={{
                      height: '36px',
                      width: '36px',
                      borderRadius: '2px',
                      flexShrink: 0,
                      m: '12px',
                    }}
                  />
                )}
              </Box>
              <Row
                sx={{
                  opacity: collapsed ? 0 : 1,
                  transition: 'all 0.22s ease',
                  width: '192px',
                  height: '56px',
                  boxSizing: 'border-box',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    lineHeight: '120%',
                    letterSpacing: '-0.2px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {workspace.name || ''}
                </Typography>
              </Row>
            </Row>
          )
        }}
        onChange={handleChange}
        fullWidth={true}
        sx={{
          borderRadius: 0,
          color: (theme) => theme.palette.primary.dark,
          fontWeight: 500,
          fontSize: '0.8rem',
          '& .MuiOutlinedInput-notchedOutline': {
            //borderColor: theme.palette.divider,
            border: 'none',
          },
          '& .MuiOutlinedInput-input': {
            p: 0,
          },
          '& .MuiSelect-icon': {
            color: (theme) =>
              collapsed
                ? 'transparent'
                : lighten(theme.palette.primary.light, 0.5),
            opacity: collapsed ? 0 : 1,
            transition: 'all 0.22s ease',
          },
          '& .MuiSelect-select': {
            borderRadius: '0px !important',
          },
        }}
      >
        {workspaces
          .filter(({ status }) => status === 'ACTIVE')
          .map((workspace) => (
            <MenuItem
              key={workspace.id}
              value={workspace.id}
              selected={selectedWorkspace === workspace.id}
              sx={{
                borderRadius: '0px !important',
              }}
            >
              <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                <Row gap={1}>
                  <Box
                    sx={{
                      mr: '6px',
                    }}
                  >
                    {workspace?.domains?.length > 0 &&
                    workspace.domains[0].domain ? (
                      <DomainAvatar
                        domain={workspace.domains[0].domain}
                        size={36}
                        borderRadius={3}
                      />
                    ) : (
                      <Box
                        component="img"
                        src={getWorkspaceLogo(workspace)}
                        alt={workspace.name}
                        sx={{
                          width: '36px',
                          height: '36px',
                          objectFit: 'cover',
                          borderRadius: 1,
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      fontSize: '0.9rem',
                      opacity: collapsed ? 0 : 1,
                      transition: 'all 0.22s ease',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '140%',
                        letterSpacing: '-0.2px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {workspace.name || 'Unknown'}
                    </Typography>
                    {workspace.isDefault ? (
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontWeight: 300,
                          letterSpacing: '-0.2px',
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        Default workspace
                      </Typography>
                    ) : null}
                  </Box>
                </Row>
                <Tooltip
                  title="Workspace Settings"
                  arrow={true}
                  placement="right"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (selectedWorkspace !== workspace.id) {
                        setSelectedWorkspace(workspace.id)
                      }
                      navigate(routes.workspaceSettings({ id: workspace.id }))
                    }}
                  >
                    <RiSettings2Line size={20} />
                  </IconButton>
                </Tooltip>
              </Row>
            </MenuItem>
          ))}
        <MenuItem
          sx={{
            fontSize: '0.8rem',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          value="CREATE"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              px: '10px',
              py: '4px',
              mt: 1,
              borderRadius: '8px',
              width: '100%',
              border: `1px solid #dddee1`,
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              '& .remixicon': {
                color: (theme) => theme.palette.text.secondary,
              },
              '&:hover': {
                backgroundColor: (theme) => theme.palette.secondary.main,
                borderColor: (theme) => theme.palette.secondary.main,
                color: (theme) => theme.palette.secondary.contrastText,

                '& .remixicon': {
                  color: (theme) => theme.palette.secondary.contrastText,
                },
              },
            }}
          >
            <Box
              sx={{
                mr: 2,
                width: '42px',
                height: '42px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 2,
              }}
            >
              <RiCommunityFill size={24} />
            </Box>
            Create new workspace
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  ) : (
    <Box
      sx={{
        width: '100%',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        borderRadius: 0,
      }}
    />
  )
}
