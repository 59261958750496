import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'

import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Typography,
} from '@mui/material'
import {
  RiBuilding2Line,
  RiCloseLine,
  RiLineChartLine,
  RiMoneyDollarCircleLine,
  RiStickyNoteLine,
  RiUser6Line,
} from '@remixicon/react'
import dayjs from 'dayjs'
import Fuse from 'fuse.js'
import { debounce } from 'lodash'

import { useMutation, useQuery } from '@redwoodjs/web'

import {
  extractEmailDomain,
  NativeObjectFormatters,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import ObjectAvatar from '../ObjectAvatar/ObjectAvatar'
import Row from '../Row/Row'

const GET_PAGES_FOR_SEARCH = gql`
  query GetPagesForSearch($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      emoji
      domains
      people
    }
  }
`

type SearchResult = {
  objectType: string
  objectId: string
  label: string
  description?: string
  photoUrl?: string
  properties: any
  domains?: string[]
  icon?: React.ReactNode
  emoji?: string
  colors?: {
    vibrant: string
    lightVibrant: string
    darkVibrant: string
    darkMuted: string
    muted: string
  }
}

const INIT_PERSON_FROM_SEARCH = gql`
  mutation InitPersonFromSearch(
    $email: String!
    $workspaceId: String!
    $source: String!
    $firstName: String
    $lastName: String
    $userId: String
    $workAccountUuid: String
  ) {
    initPersonAsync(
      email: $email
      workspaceId: $workspaceId
      source: $source
      firstName: $firstName
      lastName: $lastName
      userId: $userId
      workAccountUuid: $workAccountUuid
    )
  }
`

const getObjectTypeIcon = (objectType: string) => {
  const iconSize = 20
  switch (objectType) {
    case 'native_contact':
      return <RiUser6Line size={iconSize} />
    case 'native_organization':
      return <RiBuilding2Line size={iconSize} />
    case 'native_pipeline':
      return <RiLineChartLine size={iconSize} />
    case 'native_opportunity':
      return <RiMoneyDollarCircleLine size={iconSize} />
    case 'page':
      return <RiStickyNoteLine size={iconSize} />
    default:
      return <></>
  }
}

const defaultTextFieldSx = { px: 2, mx: 1 }
const defaultInputSx = {
  fontWeight: 600,
  fontSize: '1.1rem',
}

const scoreResultItem = (item) => {
  if (item.objectType === 'native_opportunity') {
    return 3
  } else if (item.objectType === 'native_contact') {
    return 1
  } else if (item.objectType === 'native_organization') {
    return 2
  } else if (item.objectType === 'page') {
    return -1
  }
  return 0
}

const ObjectFinder = ({
  onSelect,
  onChangeQuery,
  sx = {},
  textFieldSx = defaultTextFieldSx,
  inputSx = defaultInputSx,
  placeholder = 'Search for people, organizations, pipelines ...',
  objectTypes,
  freeSolo = false,
  distance = 100,
}) => {
  const {
    selectedWorkspace: workspaceId,
    workspacePeople,
    workspaceOrganizations,
    pipelines,
  } = useContext(DayContext)

  const [query, setQuery] = useState<string | null>(null)
  const [currentSearchResults, setCurrentSearchResults] = useState<
    SearchResult[]
  >([])
  const [isValidFreeSoloInput, setIsValidFreeSoloInput] =
    useState<boolean>(false)

  useEffect(() => {
    if (typeof query !== 'string') {
      setQuery('')
    }
  }, [query])

  const memoizedOnChangeQuery = useCallback(
    (query: string) => {
      if (onChangeQuery) {
        onChangeQuery(query)
      }
    },
    [onChangeQuery]
  )

  const memoizedObjectTypes = useMemo(() => {
    return objectTypes || []
  }, [objectTypes])

  const { data: pagesData } = useQuery(GET_PAGES_FOR_SEARCH, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  })

  const [upsertPerson] = useMutation(INIT_PERSON_FROM_SEARCH)

  const possibleSearchResults = useMemo(() => {
    const allObjects = []

    if (
      pipelines?.length > 0 &&
      (memoizedObjectTypes.includes(NativeObjectTypes.Pipeline) ||
        memoizedObjectTypes.length === 0)
    ) {
      for (const pipeline of pipelines.filter(
        (pipeline) => pipeline.title && !pipeline.isGeneric
      )) {
        const pipelineResult: SearchResult = {
          objectType: 'native_pipeline',
          objectId: pipeline.id,
          label: pipeline.title,
          description: `Pipeline with ${pipeline.opportunityCount} opportunities`,
          photoUrl: null,
          properties: { ...pipeline },
        }
        allObjects.push(pipelineResult)
        for (const stage of pipeline.stages) {
          for (const opportunity of stage.opportunities) {
            const opportunityResult: SearchResult = {
              objectType: 'native_opportunity',
              objectId: opportunity.id,
              label: `Opportunity: ${opportunity.title}`,
              description: `"${pipeline.title}" pipeline, in the "${stage.name}" stage`,
              properties: { ...opportunity },
            }

            allObjects.push(opportunityResult)
          }
        }
      }
    } else {
      logger.dev('no pipelines')
    }

    if (
      workspaceOrganizations?.length > 0 &&
      (memoizedObjectTypes.includes(NativeObjectTypes.Organization) ||
        memoizedObjectTypes.length === 0)
    ) {
      for (const organization of workspaceOrganizations) {
        const organizationResult: SearchResult = {
          objectType: 'native_organization',
          objectId: organization.domain,
          label: NativeObjectFormatters[NativeObjectTypes.Organization].label({
            properties: organization,
          }),
          description:
            organization.about?.aiDescription ||
            organization.about?.description ||
            organization.domain,
          photoUrl: organization?.photos?.square,
          colors: {
            vibrant: organization?.colors?.colorVibrant,
            lightVibrant: organization?.colors?.colorLightVibrant,
            darkVibrant: organization?.colors?.colorDarkVibrant,
            darkMuted: organization?.colors?.colorDarkMuted,
            muted: organization?.colors?.colorMuted,
          },
          properties: { ...organization },
        }
        allObjects.push(organizationResult)
      }
    } else {
      logger.dev({ workspaceOrganizations })
    }

    if (
      workspacePeople?.length > 0 &&
      (memoizedObjectTypes.includes(NativeObjectTypes.Contact) ||
        memoizedObjectTypes.length === 0)
    ) {
      for (const person of workspacePeople) {
        const displayId = person.email

        const label = person.fullName

        const fallbackDisplayId = label != person.email ? displayId : null

        const personResult: SearchResult = {
          objectType: 'native_contact',
          objectId: person.email,
          label,
          description: person.currentJobTitle || fallbackDisplayId,
          photoUrl: person.photoUrl,
          domains: [extractEmailDomain(person.email)],
          properties: { ...person },
        }
        allObjects.push(personResult)
      }
    }

    if (
      pagesData?.pages?.length > 0 &&
      (memoizedObjectTypes.includes(NativeObjectTypes.Page) ||
        memoizedObjectTypes.length === 0)
    ) {
      for (const page of pagesData.pages) {
        const pageResult: SearchResult = {
          objectType: 'page',
          objectId: page.id,
          label: page.title,
          domains: page.domains,
          description: `Page created ${dayjs(page.createdAt).format(
            'MMM D, YY'
          )}`,
          emoji: page.emoji,
          properties: { ...page },
        }
        allObjects.push(pageResult)
      }
    }

    return allObjects
  }, [
    pipelines,
    pagesData,
    memoizedObjectTypes,
    workspacePeople,
    workspaceOrganizations,
  ])

  const setSearchResults = useCallback((results: SearchResult[]) => {
    setCurrentSearchResults(results)
  }, [])

  const handleQueryChange = useCallback((newQuery: string) => {
    setQuery(newQuery)
  }, [])

  const searchOptions = useMemo(() => {
    return {
      keys: ['label', 'objectId', 'description', 'domains'],
      includeScore: true,
      includeMatches: true,
      threshold: 0.2,
      distance,
    }
  }, [distance])

  const fuse = useMemo(() => {
    const index = new Fuse(possibleSearchResults, searchOptions)
    return index
  }, [possibleSearchResults, searchOptions])

  // Memoize the search function
  const searchForObjects = useCallback(
    (query) => {
      if (query.length === 0) {
        setSearchResults([])
        setIsValidFreeSoloInput(false)
      } else if (query.length > 2) {
        const results = fuse.search(query)
        logger.dev({ results })

        const filteredResults = results
          .map((result) => result.item)
          .sort((a, b) => {
            return scoreResultItem(b) - scoreResultItem(a)
          })

        setSearchResults(filteredResults)
        if (freeSolo) {
          let bestMatch = 0
          for (const result of results) {
            if (result.score > 0.1) {
              bestMatch = result.score
            }
          }

          // Check if the query is a valid email or website domain
          const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(query)
          const isValidDomain = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(
            query
          )

          const suspectedMatch = bestMatch > 0.1

          setIsValidFreeSoloInput(
            !suspectedMatch && (isValidEmail || isValidDomain)
          )
        }
      }
    },
    [fuse, freeSolo, setSearchResults]
  )

  const debouncedSearchForObjects = useMemo(
    () =>
      debounce((q) => {
        searchForObjects(q)
        memoizedOnChangeQuery(q)
      }, 250),
    [searchForObjects, memoizedOnChangeQuery]
  )

  // Move the cleanup effect after the debugging effect
  useEffect(() => {
    return () => {
      logger.dev('Cleaning up debounced search')
      debouncedSearchForObjects.cancel()
    }
  }, [debouncedSearchForObjects])

  const componentRef = useRef(null)

  // Separate effect for search
  useEffect(() => {
    if (typeof query === 'string') {
      debouncedSearchForObjects(query)
    }
  }, [query, debouncedSearchForObjects])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        handleQueryChange('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleQueryChange])

  const handleAddClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (query.includes('@')) {
        upsertPerson({
          variables: {
            email: query,
            workspaceId,
            source: 'manual',
          },
        })
      }
      onSelect({
        objectId: query,
        objectType: query.includes('@')
          ? NativeObjectTypes.Contact
          : NativeObjectTypes.Organization,
      })
    },
    [query, workspaceId, upsertPerson, onSelect]
  )

  return (
    typeof query === 'string' && (
      <Box
        sx={sx}
        className={query.length > 0 ? 'has-query' : ''}
        ref={componentRef}
      >
        <TextField
          variant="standard"
          placeholder={placeholder}
          value={query}
          onChange={(e) => {
            if (e?.nativeEvent?.inputType === 'insertFromPaste') {
              // Clean up pasted content
              const pastedValue = e.target.value.trim().toLowerCase()
              // Remove common prefixes
              const cleanedValue = pastedValue
                .replace(/^(https?:\/\/)?(www\.)?/, '')
                .replace(/\/.*$/, '') // Remove anything after the domain
                .toLowerCase() // Ensure the domain is lowercase

              handleQueryChange(cleanedValue)
            } else {
              // For regular typing, just update as normal
              handleQueryChange(e.target.value)
            }
          }}
          fullWidth={true}
          autoFocus={true}
          InputProps={{
            disableUnderline: true,
            sx: inputSx,
            endAdornment: query?.length > 0 && (
              <Row gap={1}>
                {freeSolo && isValidFreeSoloInput && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleAddClick}
                  >
                    Add
                  </Button>
                )}
                <IconButton onClick={() => handleQueryChange('')}>
                  <RiCloseLine />
                </IconButton>
              </Row>
            ),
          }}
          sx={textFieldSx}
        />
        {currentSearchResults.length > 0 && (
          <List
            className="object-finder-results"
            sx={{ px: 1, overflowY: 'auto' }}
          >
            {currentSearchResults.map((result, index) => (
              <ListItemButton
                key={`searchResult_${result.objectId}_${index}`}
                onClick={() => onSelect(result)}
                sx={{ width: '100%', borderRadius: '4px' }}
              >
                <Row
                  sx={{
                    justifyContent: 'space-between',
                    width: 'calc(100%)',
                  }}
                >
                  <Row
                    sx={{
                      flexShrink: 1,
                      width: 'calc(100% - 24px)',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {result.icon || result.emoji || (
                      <ObjectAvatar
                        crmObject={{
                          objectType: result.objectType,
                          objectId: result.objectId,
                          properties: {
                            photoUrl: result.photoUrl,
                            ...result.properties,
                          },
                        }}
                        size={36}
                      />
                    )}
                    <Box
                      sx={{
                        px: 2,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '0.9rem',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {result.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.7rem',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {result.description}
                      </Typography>
                    </Box>
                  </Row>
                  <Box
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      width: '20px',
                    }}
                  >
                    {getObjectTypeIcon(result.objectType)}
                  </Box>
                </Row>
              </ListItemButton>
            ))}
          </List>
        )}
      </Box>
    )
  )
}

export default ObjectFinder
